// Imports
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import dateFormat from 'date-fns/format'
import dateParseISO from 'date-fns/parseISO'

// UI Imports
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@mui/material/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from "@mui/material/Checkbox"
import { withStyles } from '@material-ui/core'
import styles from './styles'

// App Imports
import params from '../../../setup/config/params'
import { messageShow } from '../../common/api/actions'
import SectionPaper from '../../common/SectionPaper'
import Loading from '../../common/Loading'
import EmptyMessage from '../../common/EmptyMessage'
import Image from '../../common/Image'
import { list } from '../api/actions/query'
import { remove, publishToggle } from '../api/actions/mutation'
import routes, { getImageSource } from '../api/routes'

// Component
class List extends PureComponent {

  componentDidMount() {
    this.refresh()
  }

  refresh = () => {
    const { list } = this.props

    list()
  }

  onPublishToggle = bannerId => async event => {
    const isPublished = event.target.checked

    let check = window.confirm(`Are you sure you want to ${ isPublished ? 'publish' : 'unpublish' } this banner?`)

    if(check) {
      const { publishToggle, messageShow, match } = this.props

      try {
        const { data } = await publishToggle({ bannerId, isPublished })

        messageShow(data.message)

        if(data.success) {
          this.refresh(match.params.page, false)()
        }
      } catch (error) {
        messageShow('Some error occurred. Please try again.')
      }
    }
  }

  onDelete = (bannerId) => async () => {
    let check = window.confirm('Are you sure you want to delete this banner?')

    if(check) {
      const { remove, list, messageShow } = this.props

      try {
        const { data } = await remove({ bannerId })

        messageShow(data.message)

        if(data.success) {
          list()
        }
      } catch (error) {
        messageShow('Some error occurred. Please try again.')
      }
    }
  }

  render() {
    const { banners: { isLoading, list }, classes } = this.props

    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            Banners
          </Typography>

          <Link to={routes.bannerCreate.path}>
            <Button color="inherit">Create</Button>
          </Link>
        </Toolbar>

        <SectionPaper>
          {
            isLoading
              ? <Loading />
              : list.length === 0
                ? <EmptyMessage message={'You have not added any banners yet.'} />
                : <Table padding={"normal"}>
                    <TableHead>
                      <TableRow>
                        <TableCell width={85}>Image</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell width={100}>Type</TableCell>
                        <TableCell width={50}>Sort</TableCell>
                        <TableCell width={180}>Updated</TableCell>
                        <TableCell width={80} align="center">Published</TableCell>
                        <TableCell width={180} align="center">Actions</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {
                        list.map(({ _id, image, name, sort, type, updatedAt, isPublished }) =>
                          <TableRow key={_id}>
                            <TableCell>
                              <Link to={routes.bannerEdit.path(_id)}>
                                <Image
                                  src={getImageSource(`banner/${image}`)}
                                  defaultSrc={getImageSource()}
                                />
                              </Link>
                            </TableCell>
                            <TableCell>{ name }</TableCell>
                            <TableCell>{ type }</TableCell>
                            <TableCell>{ sort }</TableCell>
                            <TableCell>{ dateFormat(dateParseISO(updatedAt), params.date.format.full) }</TableCell>
                            <TableCell align="center">
                              <Checkbox
                                checked={isPublished}
                                onChange={this.onPublishToggle(_id)}
                                color="primary"
                              />
                            </TableCell>
                            <TableCell align="center">
                              <Button component={Link} to={routes.bannerEdit.path(_id)}>Edit</Button>
                              <Button onClick={this.onDelete(_id)}>Delete</Button>
                            </TableCell>
                          </TableRow>
                        )
                      }
                    </TableBody>
                  </Table>
          }
        </SectionPaper>
      </div>
    )
  }
}

// Component Properties
List.propTypes = {
  banners: PropTypes.object.isRequired,
  list: PropTypes.func.isRequired,
  publishToggle: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
}

// Component State
function listState(state) {
  return {
    banners: state.banners
  }
}

export default connect(listState, { list, publishToggle, remove, messageShow })(withStyles(styles)(List))
