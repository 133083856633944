// Imports
import axios from 'axios'
// App Imports
import { API_URL } from '../../../../setup/config/env'


// Actions

// Get list
export function getBroadcastList() {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'broadcastList'
    })
  }
}
