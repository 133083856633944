// Imports
import { withStyles } from '@material-ui/core'
import React, { PureComponent , useState }  from 'react'
import { connect } from 'react-redux'
import 'react-bootstrap';
import { Container, Row, Col } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { messageShow } from '../../common/api/actions'
import { remove } from '../api/actions/mutation'
import { Link } from 'react-router-dom'
import routes from '../api/routes'
import styles from './styles'
import Points from '../Points';
import Goals from '../Goals'
import Rewards from '../Rewards'
import Leaderboard from '../Leaderboard';

// Component
class List extends PureComponent {
  
  render() {
    const {
      classes
    } = this.props;
    return (
      <section id="menu" className="mt-5">
        <div className="clearfix mb-5 pb-5">

        
          <Container fluid>
            <Row className="mb-10 justify-content-center">
              <Col>
                <Tabs
                  defaultActiveKey="Goals"
                  transition={false}
                >
                  <Tab
                    eventKey="Goals"
                    title={<h1 style={{ fontSize: '15px'}}>Goals</h1>}
                    style={{ color: 'blue' }}
                  >
                  <Goals/>
                  </Tab>
                  <Tab
                    eventKey="Points"
                    title={<h1 style={{ fontSize: '15px' }}>Points</h1>}
                  >
                  <Points />
                  </Tab>
                  <Tab
                    eventKey="Rewards"
                    title={<h1 style={{ fontSize: '15px' }}>Rewards</h1>}
                  >
                   <Rewards/>
                  </Tab>
                  <Tab
                    eventKey="LeaderBoard"
                    title={<h1 style={{ fontSize: '15px' }}>LeaderBoard</h1>}
                  >
                    <Leaderboard/>
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    );
}
}

export default connect(null, { messageShow })(withStyles(styles)(List))
