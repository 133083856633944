// App Imports
import {
  GET_DASHBOARD_COUNT_DONE,
  GET_DASHBOARD_COUNT_FILTER,
  GET_DASHBOARD_COUNT_REQUEST,
  GET_DASHBOARD_COUNT_RESET,
  GET_DASHBOARD_COUNT_RESPONSE,
} from "../actions/types";

// User list (dashboardCounts)

// Initial State
const dashboardCountsInitialState = {
  isLoading: false,
  counts: {
    product: 0,
    category: 0,
    order: 0,
    vendor: 0,
    customer: 0,
  },
};

// State
export default (state = dashboardCountsInitialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_COUNT_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
      };

    case GET_DASHBOARD_COUNT_RESPONSE:
      return {
        ...state,
        counts: action.counts,
      };

    case GET_DASHBOARD_COUNT_DONE:
      return {
        ...state,
        isLoading: false,
      };

    case GET_DASHBOARD_COUNT_RESET:
      return { ...dashboardCountsInitialState };

    case GET_DASHBOARD_COUNT_FILTER:
      return {
        ...state,
        counts: action.counts,
      };

    default:
      return state;
  }
};
