// UI Imports

// Component Styles
const styles = theme => ({
  main: {
    marginTop: 64,
    minHeight: 'calc(100vh - 64px)',
    display: 'flex'
  },

  contentWrapper: {
    display: 'flex',
    flex: 1
  },
  content: {
    width: '100%'
  }
})

export default styles
