// Imports
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

// UI Imports
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import Typography from "@material-ui/core/Typography/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@mui/material/Button";
import { withStyles } from "@material-ui/core/styles/index";
import styles from "./styles";

// App Imports
import SectionPaper from "../../../common/SectionPaper";
import Image from "../../../common/Image";
import Loading from "../../../common/Loading";
import routes from "../../../../setup/routes";
import { imageUser } from "../../../user/api/routes";

// Component
class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  render() {
    const {
      auth: { user },
      classes,
    } = this.props;
    const { isLoading } = this.state;
    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            {user.name}
          </Typography>
        </Toolbar>

        {isLoading ? (
          <Loading />
        ) : (
          user &&
          user._id && (
            <div>
              <SectionPaper padding>
                <Grid container wrap="wrap" direction="column">
                  <Grid container direction="row" justifyContent="space-between">
                    <Grid item>
                      <Image
                        src={user.image}
                        defaultSrc={imageUser()}
                        size={110}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        component={Link}
                        to={routes.profileUpdate.path}
                        color="secondary"
                        className={classes.button}
                      >
                        Edit
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container wrap="wrap" justifyContent="space-between" spacing={4}>
                      <Grid item>
                        <Typography variant="overline">Name:</Typography>
                        <Typography variant="subtitle2">{user.name}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="overline">Email:</Typography>
                        <Typography variant="subtitle2">
                          {user.email.toLowerCase()}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography variant="overline">
                          Mobile Number:
                        </Typography>
                        <Typography variant="subtitle2">
                          {user.mobile}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="overline">Role:</Typography>
                        <Typography variant="subtitle2">{user.role}</Typography>
                      </Grid>

                      <Grid item>
                        <Typography variant="overline">Verified:</Typography>
                        <Typography variant="subtitle2">
                          {user.isVerified ? "Verified" : "Not Verified"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </SectionPaper>
            </div>
          )
        )}
      </div>
    );
  }
}

// Component Properties
Profile.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

// Component State
function profileState(state) {
  return {
    auth: state.auth,
  };
}

export default withRouter(
  connect(profileState, {})(withStyles(styles)(Profile))
);
