// Imports
import axios from 'axios'

// App Imports
import { API_URL } from '../../../../setup/config/env'
import { MESSAGE_SHOW } from '../../../common/api/actions'
import { CATEGORY_LIST_CACHE, CATEGORY_LIST_PARENT_CACHE, CATEGORY_LIST_BY_PARENT_CACHE } from './cache-keys'
import {
  LIST_REQUEST, LIST_RESPONSE, LIST_DONE,
  LIST_PARENT_REQUEST, LIST_PARENT_RESPONSE, LIST_PARENT_DONE,
  LIST_BY_PARENT_REQUEST, LIST_BY_PARENT_RESPONSE, LIST_BY_PARENT_DONE,
} from './types'

// Actions

// Get list
export function list(isLoading = true) {
  return async dispatch => {
    // Caching
    try {
      const list = JSON.parse(window.localStorage.getItem(CATEGORY_LIST_CACHE))

      if(list) {
        dispatch({
          type: LIST_RESPONSE,
          list
        })
      } else {
        dispatch({
          type: LIST_REQUEST,
          isLoading
        })
      }
    } catch(e) {
      dispatch({
        type: LIST_REQUEST,
        isLoading
      })
    }

    try {
      const { data } = await axios.post(API_URL, {
        operation: 'categoryListAll'
      })

      if(!data.success) {
        dispatch({
          type: MESSAGE_SHOW,
          success: data.success,
          message: data.message
        })
      } else {
        const list = data.data

        dispatch({
          type: LIST_RESPONSE,
          list
        })

        window.localStorage.setItem(CATEGORY_LIST_CACHE, JSON.stringify(list))
      }
    } catch(error) {
      dispatch({
        type: MESSAGE_SHOW,
        success: false,
        message: error.message
      })
    } finally {
      dispatch({
        type: LIST_DONE,
        isLoading: false
      })
    }
  }
}

// Get parent list
export function listParent(isLoading = true) {
  return async dispatch => {
    // Caching
    try {
      const list = JSON.parse(window.localStorage.getItem(CATEGORY_LIST_PARENT_CACHE))

      if(list) {
        dispatch({
          type: LIST_PARENT_RESPONSE,
          list
        })
      } else {
        dispatch({
          type: LIST_PARENT_REQUEST,
          isLoading
        })
      }
    } catch(e) {
      dispatch({
        type: LIST_PARENT_REQUEST,
        isLoading
      })
    }

    try {
      const { data } = await axios.post(API_URL, {
        operation: 'categoryListParent'
      })

      if(!data.success) {
        dispatch({
          type: MESSAGE_SHOW,
          success: data.success,
          message: data.message
        })
      } else {
        const list = data.data

        dispatch({
          type: LIST_PARENT_RESPONSE,
          list
        })

        window.localStorage.setItem(CATEGORY_LIST_PARENT_CACHE, JSON.stringify(list))
      }
    } catch(error) {
      dispatch({
        type: MESSAGE_SHOW,
        success: false,
        message: error.message
      })
    } finally {
      dispatch({
        type: LIST_PARENT_DONE,
        isLoading: false
      })
    }
  }
}

// Get list by parent category
export function listByParent(parentId, isLoading = true) {
  return async dispatch => {
    // Caching
    const CACHE_KEY = CATEGORY_LIST_BY_PARENT_CACHE + parentId

    try {
      const list = JSON.parse(window.localStorage.getItem(CACHE_KEY))

      if(list) {
        dispatch({
          type: LIST_BY_PARENT_RESPONSE,
          list
        })
      } else {
        dispatch({
          type: LIST_BY_PARENT_REQUEST,
          isLoading
        })
      }
    } catch(e) {
      dispatch({
        type: LIST_BY_PARENT_REQUEST,
        isLoading
      })
    }

    try {
      const { data } = await axios.post(API_URL, {
        operation: 'categoryListByParent',
        params: { parentId }
      })

      if(!data.success) {
        dispatch({
          type: MESSAGE_SHOW,
          success: data.success,
          message: data.message
        })
      } else {
        const list = data.data

        dispatch({
          type: LIST_BY_PARENT_RESPONSE,
          list
        })

        window.localStorage.setItem(CACHE_KEY, JSON.stringify(list))
      }
    } catch(error) {
      dispatch({
        type: MESSAGE_SHOW,
        success: false,
        message: error.message
      })
    } finally {
      dispatch({
        type: LIST_BY_PARENT_DONE,
        isLoading: false
      })
    }
  }
}

// Get detail
export function detail({ categoryId }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'categoryDetail',
      params: { categoryId }
    })
  }
}

// Get count
export function getCount() {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'categoryCount'
    })
  }
}
