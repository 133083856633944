// Imports
import axios from 'axios'
// App Imports
import { API_URL } from '../../../../setup/config/env'


// Actions

// Save
export function save(broadcasts) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'broadcastSave',
      params: broadcasts
    })
  }
}

//remove
export function remove(broadcastId) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'broadcastDelete',
      params: broadcastId
    })
  }
}
