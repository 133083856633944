// App Imports
import params from '../../../setup/config/params'
import List from '../List'
import Detail from '../Detail'
import Update from '../Update'

// Pages routes
export default {
  customerList: {
    path: (page = ':page') => `/customers/${ page }`,
    activePath: '/customer',
    component: List,
    auth: true,
    role: params.user.roles.admin.key
  },

  customerDetail: {
    path: (userId = ':userId') => (`/customer/detail/${ userId }`),
    component: Detail,
    auth: true,
    role: params.user.roles.admin.key
  },

  customerUpadte: {
    path: (userId = ':userId') => (`/customer/edit/${ userId }`),
    component: Update,
    auth: true,
    role: params.user.roles.admin.key
  }
}
