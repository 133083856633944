// Imports
import axios from 'axios'

// App Imports
import { API_URL } from '../../../../setup/config/env'

// Actions

// Create or update
export function createOrUpdate({ category }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'categoryCreateOrUpdate',
      params: category
    })
  }
}

// Feature toggle
export function featureToggle({ categoryId, isFeatured }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'categoryFeatureToggle',
      params: { categoryId, isFeatured }
    })
  }
}

// Publish toggle
export function publishToggle({ categoryId, isPublished }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'categoryPublishToggle',
      params: { categoryId, isPublished }
    })
  }
}

// Delete
export function remove({ categoryId }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'categoryDelete',
      params: { categoryId  }
    })
  }
}
