// Imports
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'

// UI Imports
import Button from '@mui/material/Button'
import IconButton from '@material-ui/core/IconButton'
import IconArrowBack from '@material-ui/icons/ArrowBack'
import IconArrowForward from '@material-ui/icons/ArrowForward'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'

// App Imports
import params from '../../../setup/config/params'

// Component
class Pagination extends PureComponent {

  constructor(props) {
    super(props)

    this.state = {}
  }

  paginateShow = () => {
    const { count } = this.props

    return count > params.common.pagination.default
  }

  paginate = () => {
    const { count, match } = this.props

    let currentPage = parseInt(match.params.page) || 1
    let pages = []
    let lastPage = Math.ceil(count / params.common.pagination.default)

    if(currentPage > 1) {
      pages.push({
        page: currentPage - 1,
        display: '<'
      })
    }

    pages.push({
      page: 1,
      display: 1
    })

    if(currentPage > 5) {
      pages.push({
        page: 0,
        display: '...'
      })
    }

    for(let i = 2; i < lastPage; i++) {
      if(currentPage + 3 > i && currentPage - 3 < i) {
        pages.push({
          page: i,
          display: i
        })
      }
    }

    if(currentPage + 5 <= lastPage) {
      pages.push({
        page: 0,
        display: '...'
      })
    }

    pages.push({
      page: lastPage,
      display: lastPage
    })

    if(currentPage < lastPage) {
      pages.push({
        page: currentPage + 1,
        display: '>'
      })
    }

    return pages
  }

  render() {
    const { route, match, classes } = this.props

    return (
      <div className={classes.paginationWrapper}>
        {
          this.paginateShow() &&
          <div className={classes.pagination}>
            {
              this.paginate().map(({ page, display }, i) => (
                <React.Fragment key={ i }>
                  {
                    display === '<' &&
                    <IconButton
                      aria-label="Previous"
                      component={Link}
                      to={route.path(page)}
                    >
                      <IconArrowBack />
                    </IconButton>
                  }

                  {
                    page !== 0 && display !== '<' && display !== '>' &&
                    <Button
                      size="small"
                      component={Link}
                      to={route.path(page)}
                      variant={ parseInt(match.params.page) === page ? 'contained' : 'text' }
                    >
                      { display }
                    </Button>
                  }

                  { display === '...' && <Button size="small" disabled>{ display }</Button> }

                  {
                    display === '>' &&
                    <IconButton
                      aria-label="Next"
                      component={Link}
                      to={route.path(page)}
                    >
                      <IconArrowForward />
                    </IconButton>
                  }
                </React.Fragment>
              ))
            }
          </div>
        }
      </div>
    )
  }
}

// Component Properties
Pagination.propTypes = {
  count: PropTypes.number.isRequired,
  route: PropTypes.any.isRequired,
  classes: PropTypes.object.isRequired
}

export default withRouter(withStyles(styles)(Pagination))
