// App Imports
import params from '../../../setup/config/params'
import Save from '../Save'

// Pages routes
export default {
  settings: {
    path: '/settings',
    component: Save,
    exact: true,
    auth: true,
    role: params.user.roles.admin.key
  },
}
