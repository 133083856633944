// Imports
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// UI Imports
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  FormGroup,
  Radio,
  Divider,
  Switch,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Grid from "@material-ui/core/Grid/Grid";
import TextField from "@mui/material/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

// Accordion
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
// import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@material-ui/core/Typography";

// Tabs
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

// ---- Datepicket UI Library imports
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import dayjsUTC from "dayjs/plugin/utc";
import dayjsTz from "dayjs/plugin/timezone";
import customFormat from "dayjs/plugin/customParseFormat";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

// App Imports
import { nullToEmptyString } from "../../../setup/helpers";
import { messageShow } from "../../common/api/actions";
import SectionPaper from "../../common/SectionPaper";
import Loading from "../../common/Loading";
import { detail } from "../api/actions/query";
import { save, updateSettings } from "../api/actions/mutation";

import { encrption_secrey_key } from "../../../setup/config/env";
import timeZones from "../../../setup/config/timezone.json";

import moment from 'moment';

import CryptoJS from "crypto-js";

// Component
class CreateOrUpdate extends Component {
  constructor(props) {
    super(props);
    dayjs.extend(customFormat);
    this.secretPass = encrption_secrey_key;
    this.settings = {
      _id: "",
      phoneLine1: "",
      phoneLine2: "",
      squarePayAppId: "",
      squarePaySecret: "",
      squarePayLocation: "",
      workingHours: [
        {
          day: "SUN",
          isHoliday: false,
          bStartTime: null,
          bEndTime: null,
          lStartTime: null,
          lEndTime: null,
        },
        {
          day: "MON",
          isHoliday: false,
          bStartTime: null,
          bEndTime: null,
          lStartTime: null,
          lEndTime: null,
        },
        {
          day: "TUE",
          isHoliday: false,
          bStartTime: null,
          bEndTime: null,
          lStartTime: null,
          lEndTime: null,
        },
        {
          day: "WED",
          isHoliday: false,
          bStartTime: null,
          bEndTime: null,
          lStartTime: null,
          lEndTime: null,
        },
        {
          day: "THU",
          isHoliday: false,
          bStartTime: null,
          bEndTime: null,
          lStartTime: null,
          lEndTime: null,
        },
        {
          day: "FRI",
          isHoliday: false,
          bStartTime: null,
          bEndTime: null,
          lStartTime: null,
          lEndTime: null,
        },
        {
          day: "SAT",
          isHoliday: false,
          bStartTime: null,
          bEndTime: null,
          lStartTime: null,
          lEndTime: null
        },
      ],
      timeZone: "",
      taxAmount: "",
    };

    this.state = {
      isLoading: false,
      isSubmitting: false,
      settings: this.settings,
      expanded: "panel1",
      tabValue: "1",
      gVal: null,
    };
  }

  handleAccordion = (panel) => {
    const value = this.state.expanded === panel ? false : panel;
    this.setState({ expanded: value });
  };

  handleTabs = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  componentDidMount() {
    this.refresh();
  }

  refresh = async () => {
    const { detail, messageShow } = this.props;

    this.isLoadingToggle(true);

    try {
      const { data } = await detail();

      if (data.success && data.data) {
        data.data.squarePayAppId = data.data.squarePayAppId
          ? await this.decryptData(data.data.squarePayAppId)
          : "";
        data.data.squarePaySecret = data.data.squarePaySecret
          ? await this.decryptData(data.data.squarePaySecret)
          : "";
        data.data.squarePayLocation = data.data.squarePayLocation
          ? await this.decryptData(data.data.squarePayLocation)
          : "";
        

        if (data.data?.workingHours?.length > 0) {
          const workHrs = data.data.workingHours?.map(eWorkHrs => {
            for (let key in eWorkHrs) {
              if (eWorkHrs[key] && key.includes('Time')) {
                eWorkHrs[key] = dayjs(eWorkHrs[key].toString(), 'HH:mm');
              }
            }
            return eWorkHrs;
          })
          data.data.workingHours = workHrs;
        } else {
          data.data.workingHours = this.settings.workingHours
        }
        data.data.timeZone =
          data.data.timeZone !== undefined ? data.data.timeZone : "";
        this.setState({
          settings: data.data,
        });
      }
    } catch (error) {
      messageShow("Some error occurred. Please try again.");
    } finally {
      this.isLoadingToggle(false);
    }
  };

  encryptData = async (value) => {
    const data = CryptoJS.AES.encrypt(
      JSON.stringify(value),
      this.secretPass
    ).toString();
    return data;
  };

  decryptData = async (value) => {
    const bytes = CryptoJS.AES.decrypt(value, this.secretPass);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return data;
  };

  onSave = async (event) => {
    event.preventDefault();

    const { save, messageShow } = this.props;

    const { settings } = this.state;

    this.isSubmittingToggle(true);

    try {
      //console.log("Secret ", await this.encryptData(settings.squarePayAppId))
      settings.squarePayAppId = await this.encryptData(settings.squarePayAppId);
      settings.squarePaySecret = await this.encryptData(
        settings.squarePaySecret
      );
      settings.squarePayLocation = await this.encryptData(
        settings.squarePayLocation
      );
      const { data } = await save(settings);
      this.isSubmittingToggle(false);
      messageShow(data.message);
    } catch (error) {
      this.isSubmittingToggle(false);

      messageShow("Some error occurred. Please try again.");
    }
  };

  isLoadingToggle = (isLoading) => {
    this.setState({
      isLoading,
    });
  };

  isSubmittingToggle = (isSubmitting) => {
    this.setState({
      isSubmitting,
    });
  };

  onChange = ({ target: { name, value } }) => {
    const { settings } = this.state;
    settings[name] = value;
    this.setState({
      settings,
    });
  };

  onChangeTime = (newVal, mode, day, isHoliday) => {
    const workingHours = [...this.state.settings.workingHours].map((mWhrs) => {
      if (mWhrs.day === day) {
        mWhrs[mode] = newVal ? dayjs(newVal).format() : null;
        if (isHoliday !== undefined) mWhrs.isHoliday = isHoliday;
      }
      return mWhrs;
    });
    const settings = { ...this.state.settings, workingHours: workingHours };
    this.setState({ settings: settings });
  };

  resetTime = (day, resetSlot) => {
    // 'morn', 'noon', 'eve'
    const workingHours = [...this.state.settings.workingHours].map((mWhrs) => {
      if (mWhrs.day === day) {
        if (resetSlot === "morn") {
          mWhrs.bStartTime = null;
          mWhrs.bEndTime = null;
        } else {
          mWhrs.lStartTime = null;
          mWhrs.lEndTime = null;
        }
      }
      return mWhrs;
    });
    const settings = { ...this.state.settings, workingHours: workingHours };
    this.setState({ settings: settings });
  };

  appConfigChange = (type, value) => {
    const key = type === "tax" ? "taxAmount" : "timeZone";
    const settings = { ...this.state.settings, [key]: value };
    this.setState({ settings: settings });
  };

  // Save App Configuration
  saveAppConfig = () => {
    const { taxAmount, timeZone } = this.state.settings;
    this.modifySettings({ taxAmount, timeZone });
  };

  // Save working Hours
  saveAppWorkingHours = () => {
    let workingHours = JSON.parse(JSON.stringify(this.state.settings.workingHours));
    this.isSubmittingToggle(true);
    workingHours.map(eWrkhrs => {
      if (!eWrkhrs.isHoliday) {
        for (let key in eWrkhrs) {
          if (eWrkhrs[key] && key.includes('Time')) {
            eWrkhrs[key] = dayjs(eWrkhrs[key].toString()).format('HH:mm');
          }
        }
        return eWrkhrs;
      }
    });
    this.modifySettings({ workingHours: workingHours });
  };

  // Save payment condifuration
  savePaymentConfig = async () => {
    const { settings } = this.state;
    const squarePayAppId = await this.encryptData(settings.squarePayAppId);
    const squarePaySecret = await this.encryptData(settings.squarePaySecret);
    const squarePayLocation = await this.encryptData(
      settings.squarePayLocation
    );
    this.isSubmittingToggle(true);
    this.modifySettings({
      squarePayAppId: squarePayAppId,
      squarePaySecret: squarePaySecret,
      squarePayLocation: squarePayLocation,
      phoneLine1: settings.phoneLine1,
      phoneLine2: settings.phoneLine2
    });
  };

  // Update Specific data only
  modifySettings = async (requestObject) => {
    // requestObject as Object
    const { updateSettings, messageShow } = this.props;
    const { data } = await updateSettings({
      requestObject,
      _id: this.state.settings?._id ? this.state.settings?._id : null,
    });
    this.isSubmittingToggle(false);
    messageShow(data.message);
  };

  onCloseEvent = (mode, index) => {
    let { workingHours } = this.state.settings;
    if (mode === "bStartTime") {
      workingHours[index].bEndTime = dayjs(workingHours[index].bStartTime).format();
    };
    if (mode === "lStartTime") {
      workingHours[index].lEndTime = dayjs(workingHours[index].lStartTime).format();
    };
    const settings = { ...this.state.settings, workingHours: workingHours };
    this.setState({ settings: settings });
  };

  render() {
    const { classes } = this.props;
    const { isLoading, isSubmitting, settings } = this.state;

    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" classes={classes.grow}>
            Settings
          </Typography>
        </Toolbar>

        <div style={{ padding: "18px" }}>
          <Grid item xs={12}>
            <Accordion
              expanded={this.state.expanded === "panel1"}
              onChange={(event) => this.handleAccordion("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  <b>App Configuration</b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <form>
                  <div className="col-12 d-lg-flex flex-lg-row flex-lg-nowrap">
                    <div className="col-lg-3 col-md-12 px-3 pb-3">
                      <FormControl style={{ width: 175 }}>
                        <InputLabel id="demo-simple-select-label">
                          Timezone
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={settings.timeZone}
                          name="timeZone"
                          label="- Choose Timezone -"
                          variant="standard"
                          onChange={(event) =>
                            this.appConfigChange("timeZone", event.target.value)
                          }
                        >
                          <MenuItem selected disabled>
                            - Choose Timezone -
                          </MenuItem>
                          {timeZones?.map((eTime, eIndex) => {
                            return (
                              <MenuItem key={eIndex} value={eTime?.zone}>
                                {eTime.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-lg-3 col-md-12 px-3 pb-3">
                      <TextField
                        id="standard-basic"
                        label="Tax Amount"
                        placeholder="Enter Tax Amount"
                        variant="standard"
                        name="taxAmount"
                        value={settings?.taxAmount}
                        onChange={(event) =>
                          this.appConfigChange("tax", event.target.value)
                        }
                        fullWidth
                      />
                    </div>
                  </div>
                  <div className="col-12 py-2 text-end">
                    <Grid
                      container
                      justifyContent="center"
                      className={classes.buttonsContainer}
                    >
                      <Button
                        aria-label={"Save"}
                        size="medium"
                        color={"primary"}
                        disabled={isSubmitting}
                        variant="contained"
                        onClick={this.saveAppConfig}
                      >
                        Save
                      </Button>
                    </Grid>
                  </div>
                </form>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={this.state.expanded === "panel2"}
              onChange={(event) => this.handleAccordion("panel2")}
              disabled={!settings.timeZone}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  <b>Working Hours</b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {!this.state.tabValue ? (
                  <div>Loading.... {this.state.tabValue}</div>
                ) : (
                  <>
                    <TabContext value={this.state.tabValue}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList
                          onChange={this.handleTabs}
                          aria-label="lab API tabs example"
                        >
                          <Tab label="Sunday" value="1" />
                          <Tab label="Monday" value="2" />
                          <Tab label="Tuesday" value="3" />
                          <Tab label="Wednesday" value="4" />
                          <Tab label="Thursday" value="5" />
                          <Tab label="Friday" value="6" />
                          <Tab label="Saturday" value="7" />
                        </TabList>
                      </Box>
                      {settings.workingHours?.map((eItem, eIndex) => {
                        return (
                          <TabPanel key={eItem.day} value={`${eIndex + 1}`}>
                            <div className="col-12 d-flex flex-column flex-wrap">
                              <div className="col-12 d-flex flex-row align-items-center gap-3 pb-3">
                                <label className="col-2 text-end">
                                  Holiday :
                                </label>
                                <FormControl>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={eItem.isHoliday}
                                        onChange={(event) =>
                                          this.onChangeTime(
                                            null,
                                            null,
                                            eItem.day,
                                            event.target.checked
                                          )
                                        }
                                        name="isHoliday"
                                      />
                                    }
                                  />
                                </FormControl>
                              </div>
                              <div className="col-12 d-flex flex-row align-items-center gap-3 mb-3">
                                <label className="col-2 text-end">
                                  Morning Shift Hours :
                                </label>
                                <div className="col-lg-4 col-md-5 col-sm-12">
                                  <DemoContainer components={["TimePicker"]}>
                                    <TimePicker
                                      label="Start Time"
                                      onChange={(newVal) =>
                                        this.onChangeTime(
                                          newVal,
                                          "bStartTime",
                                          eItem.day
                                        )
                                      }
                                      value={
                                        eItem?.bStartTime
                                          ? dayjs(eItem?.bStartTime)
                                          : null
                                      }
                                      onClose={() =>
                                        this.onCloseEvent("bStartTime", eIndex)
                                      }
                                      disabled={eItem.isHoliday}
                                    />
                                  </DemoContainer>
                                </div>
                                <div className="col-lg-4 col-md-5 col-sm-12">
                                  <DemoContainer components={["TimePicker"]}>
                                    <TimePicker
                                      label="End Time"
                                      onChange={(newVal) =>
                                        this.onChangeTime(
                                          newVal,
                                          "bEndTime",
                                          eItem.day
                                        )
                                      }
                                      minTime={
                                        eItem?.bStartTime
                                          ? dayjs(eItem?.bStartTime)
                                          : null
                                      }
                                      value={
                                        eItem?.bEndTime
                                          ? dayjs(eItem?.bEndTime)
                                          : null
                                      }
                                      disabled={eItem.isHoliday}
                                    />
                                  </DemoContainer>
                                </div>
                                <span className="col">
                                  <Button
                                    color="secondary"
                                    variant="contained"
                                    type="button"
                                    onClick={() =>
                                      this.resetTime(eItem.day, "morn")
                                    }
                                  >
                                    Reset
                                  </Button>
                                </span>
                              </div>
                              <Divider />
                              <div className="col-12 d-flex flex-row align-items-center gap-3 my-3">
                                <label className="col-2 text-end">
                                  Evening Shift Hours :
                                </label>
                                <div className="col-lg-4 col-md-5 col-sm-12">
                                  <DemoContainer components={["TimePicker"]}>
                                    <TimePicker
                                      label="Start Time"
                                      onChange={(newVal) =>
                                        this.onChangeTime(
                                          newVal,
                                          "lStartTime",
                                          eItem.day
                                        )
                                      }
                                      minTime={
                                        eItem?.bEndTime
                                          ? dayjs(eItem?.bEndTime)
                                          : null
                                      }
                                      value={
                                        eItem?.lStartTime
                                          ? dayjs(eItem?.lStartTime)
                                          : null
                                      }
                                      onClose={() =>
                                        this.onCloseEvent("lStartTime", eIndex)
                                      }
                                      disabled={eItem.isHoliday}
                                    />
                                  </DemoContainer>
                                </div>
                                <div className="col-lg-4 col-md-5 col-sm-12">
                                  <DemoContainer components={["TimePicker"]}>
                                    <TimePicker
                                      label="End Time"
                                      onChange={(newVal) =>
                                        this.onChangeTime(
                                          newVal,
                                          "lEndTime",
                                          eItem.day
                                        )
                                      }
                                      minTime={
                                        eItem?.lStartTime
                                          ? dayjs(eItem?.lStartTime)
                                          : null
                                      }
                                      value={
                                        eItem?.lEndTime
                                          ? dayjs(eItem?.lEndTime)
                                          : null
                                      }
                                      disabled={eItem.isHoliday}
                                    />
                                  </DemoContainer>
                                </div>
                                <span>
                                  <Button
                                    color="secondary"
                                    variant="contained"
                                    type="button"
                                    onClick={() =>
                                      this.resetTime(eItem.day, "noon")
                                    }
                                  >
                                    Reset
                                  </Button>
                                </span>
                              </div>
                            </div>
                          </TabPanel>
                        );
                      })}
                    </TabContext>
                    {this.state.settings._id && (
                      <div className="col-12 py-2 text-center">
                        <Grid
                          container
                          justifyContent="center"
                          item
                          className={classes.buttonsContainer}
                        >
                          <Button
                            aria-label={"Save"}
                            size="medium"
                            color={"primary"}
                            disabled={isSubmitting}
                            variant="contained"
                            onClick={this.saveAppWorkingHours}
                          >
                            Save
                          </Button>
                        </Grid>
                      </div>
                    )}
                  </>
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={this.state.expanded === "panel3"}
              onChange={(event) => this.handleAccordion("panel3")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  <b>Payment Configuration</b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <form>
                  <div className="col-12 d-lg-flex flex-lg-row flex-lg-nowrap justify-content-lg-between">
                    <div className="col-lg-6 col-md-12 px-3 pb-3">
                      <TextField
                        variant="standard"
                        name={"phoneLine1"}
                        value={nullToEmptyString(settings.phoneLine1)}
                        onChange={this.onChange}
                        label={"Phone Line 1"}
                        placeholder={"Enter phone line 1"}
                        required={true}
                        margin={"dense"}
                        autoComplete={"off"}
                        autoFocus
                        fullWidth
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 px-3 pb-3">
                      <TextField
                        variant="standard"
                        name={"phoneLine2"}
                        value={nullToEmptyString(settings.phoneLine2)}
                        onChange={this.onChange}
                        label={"Phone Line 2"}
                        placeholder={"Enter phone line 2"}
                        margin={"dense"}
                        autoComplete={"off"}
                        fullWidth
                      />
                    </div>
                  </div>
                  <div className="col-12 d-lg-flex flex-lg-row flex-lg-wrap justify-content-lg-between">
                    <div className="col-lg-6 col-md-12 px-3 pb-3">
                      <TextField
                        variant="standard"
                        name={"squarePayAppId"}
                        value={nullToEmptyString(settings.squarePayAppId)}
                        onChange={this.onChange}
                        label={"Square Payment Application Id"}
                        placeholder={"Enter Square Payment Application Id"}
                        required={true}
                        margin={"dense"}
                        autoComplete={"off"}
                        className="col-lg-5 col-md-12"
                        autoFocus
                        fullWidth
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 px-3 pb-3">
                      <TextField
                        variant="standard"
                        name={"squarePaySecret"}
                        value={nullToEmptyString(settings.squarePaySecret)}
                        onChange={this.onChange}
                        label={"Square Payment Application Secret"}
                        placeholder={"Enter Square Payment Application Secret"}
                        margin={"dense"}
                        autoComplete={"off"}
                        required={true}
                        className="col-lg-5 col-md-12"
                        fullWidth
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 px-3 pb-3">
                      <TextField
                        variant="standard"
                        name={"squarePayLocation"}
                        value={nullToEmptyString(settings.squarePayLocation)}
                        onChange={this.onChange}
                        label={"Square Payment Location ID"}
                        placeholder={"Enter Square Payment Location ID"}
                        margin={"dense"}
                        autoComplete={"off"}
                        required={true}
                        fullWidth
                      />
                    </div>
                  </div>
                  <div className="col-12 px-3">
                    <FormControl component="fieldset" required>
                      <FormLabel component="legend">
                        Notification Type
                      </FormLabel>
                      <RadioGroup
                        aria-label="notificationType"
                        name="notificationType"
                        value={nullToEmptyString(settings.notificationType)}
                        onChange={this.onChange}
                      >
                        <FormControlLabel
                          value="automatic"
                          control={
                            <Radio required={!settings.notificationType} />
                          }
                          label="Automatic"
                        />
                        <FormControlLabel
                          value="manual"
                          control={<Radio />}
                          label="Manual"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </form>
                <div className="col-12 py-2 text-end">
                  <Grid
                    container
                    justifyContent="center"
                    className={classes.buttonsContainer}
                  >
                    <Button
                      aria-label={"Save"}
                      size="medium"
                      color={"primary"}
                      disabled={isSubmitting}
                      variant="contained"
                      onClick={this.savePaymentConfig}
                    >
                      Save
                    </Button>
                  </Grid>
                </div>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </div>
      </div>
    );
  }
}

// Component Properties
CreateOrUpdate.propTypes = {
  settings: PropTypes.object,
  save: PropTypes.func.isRequired,
  updateSettings: PropTypes.func.isRequired,
  detail: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

// Component State
function createOrEditState(state) {
  return {
    settings: state.settings,
  };
}

export default connect(createOrEditState, {
  save,
  detail,
  messageShow,
  updateSettings,
})(withStyles(styles)(CreateOrUpdate));
