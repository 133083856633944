// Imports
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// UI Imports
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@mui/material/Button'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'

// App Imports
import { getDashboardCounts } from '../api/actions/query'
import Section from '../../common/Section'
import Loading from '../../common/Loading'
import params from '../../../setup/config/params'

// Component
class Dashboard extends PureComponent {

  componentDidMount() {
    this.refresh()()
  }

  refresh = () => async () => {
    const { getDashboardCounts } = this.props

    await getDashboardCounts()
  }

  render() {
    const { auth: { user }, dashboardCounts: { isLoading, counts }, classes } = this.props

    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            Dashboard
          </Typography>

          <Button onClick={this.refresh()}>
            Refresh
          </Button>
        </Toolbar>

        <Section>
          {
            isLoading
              ? <Loading />
              : <Grid container spacing={10}>
                  {/* Orders */}
                  <Grid item>
                    <Card style={{ minWidth: 120 }}>
                      <CardContent>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Orders
                        </Typography>
                        <Typography variant="h2">
                          { counts.order }
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>

                  {/* Products */}
                  <Grid item>
                    <Card style={{ minWidth: 120 }}>
                      <CardContent>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Products
                        </Typography>
                        <Typography variant="h2">
                          { counts.product }
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>

                  {
                    user.role === params.user.roles.admin.key &&
                    <>
                      {/* Customers */}
                      <Grid item>
                        <Card style={{ minWidth: 120 }}>
                          <CardContent>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                              Customers
                            </Typography>
                            <Typography variant="h2">
                              { counts.customer }
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>

                      {/* Vendors */}
                      <Grid item>
                        <Card style={{ minWidth: 120 }}>
                          <CardContent>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                              Vendors
                            </Typography>
                            <Typography variant="h2">
                              { counts.vendor }
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>

                      {/* Categories */}
                      <Grid item>
                        <Card style={{ minWidth: 120 }}>
                          <CardContent>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                              Categories
                            </Typography>
                            <Typography variant="h2">
                              { counts.category }
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    </>
                  }
              </Grid>
          }
        </Section>
      </div>
    )
  }
}

// Component Properties
Dashboard.propTypes = {
  auth: PropTypes.object.isRequired,
  dashboardCounts: PropTypes.object.isRequired,
  getDashboardCounts: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
}

// Component State
function dashboardState(state) {
  return {
    auth: state.auth,
    dashboardCounts: state.dashboardCounts
  }
}

export default connect(dashboardState, { getDashboardCounts })(withStyles(styles)(Dashboard))
