// Imports
import axios from 'axios'
// App Imports
import { API_URL } from '../../../../setup/config/env'


// Actions

// Create or update
export function createOrUpdate({ pincode }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'pincodeCreateOrUpdate',
      params: pincode
    })
  }
}

// Create or update - Area
export function createOrUpdateArea({ area }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'areaCreateOrUpdate',
      params: area
    })
  }
}

// Delete
export function remove({ pincodeId }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'pincodeDelete',
      params: { pincodeId }
    })
  }
}

// Delete Area
export function removeArea({ areaId }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'areaDelete',
      params: { areaId }
    })
  }
}

// Publish toggle
export function publishToggle({ pincodeId, isPublished }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'pincodePublishToggle',
      params: { pincodeId, isPublished }
    })
  }
}

// Publish Toggle - Area
export function publishToggleArea({ areaId, isPublished }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'areaPublishToggle',
      params: { areaId, isPublished }
    })
  }
}
