// App Imports
import deliveriesBoy from './list'
import deliveriesBoyByParent from './listByParent'
import deliveriesBoyParent from './listParent'

export default {
  deliveriesBoy,
  deliveriesBoyParent,
  deliveriesBoyByParent,
}
