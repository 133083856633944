// Imports
import React from 'react'
import { Redirect } from 'react-router-dom'

// App Imports
import routes from '../../../setup/routes'

// Component
const Redirector = ({ path = routes.userLogin.path}) => (
  <Redirect to={path} />
)

export default Redirector