// Imports
import { withStyles } from '@material-ui/core'
import Button from '@mui/material/Button'
import Checkbox from "@mui/material/Checkbox"
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid/Grid'
import InputBase from '@material-ui/core/InputBase'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
// UI Imports
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import dateFormat from "date-fns/format"
import dateParseISO from "date-fns/parseISO"
import debounce from "lodash/debounce"
import PropTypes from "prop-types"
import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
// App Imports
import params from "../../../setup/config/params";
import { subString } from "../../../setup/helpers";
import { messageShow } from "../../common/api/actions";
import EmptyMessage from "../../common/EmptyMessage";
import Image from "../../common/Image";
import Loading from "../../common/Loading";
import Pagination from "../../common/Pagination";
import SectionPaper from "../../common/SectionPaper";
import {
  featureToggle,
  publishToggle,
  remove,
  productPurchase,
  onResetStocks,
} from "../api/actions/mutation";
import {
  list,
  productCollection,
  productCollectionAll,
} from "../api/actions/query";
import routes, { getImageSource } from "../api/routes";
import styles from "./styles";
import { CheckBox, Download, MoreVert, RestartAlt } from "@mui/icons-material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";

// Component
class List extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      products: [],
      productsAll: [],
      count: 0,
      countAll: 0,
      productId: "",
      purchaseCount: 0,
      purchaseShow: false,
      page: props.match.params.page || 1,
      search: "",
      categorysearch: "",
      descriptionsearch:"",
      isfeaturedsearch:false,
      ispublishedsearch:false,
      isstocksearch:false,
      anchorEl: null,
      hederMenuEl: null,
    };

    this.changeSearch = debounce(this.refresh, 500);
  }
  handleClose = () =>
    this.setState({
      purchaseCount: 0,
      purchaseShow: false,
      showPurchaseHistory: false,
    });
  handleShow = (_id) => {
    this.setState({ productId: _id, purchaseCount: 0, purchaseShow: true });
    this.menuClose();
  };
  handleShowHistory = (_id) => {
    this.setState({
      purchases: (
        this.state.products.find((product) => product._id === _id) || {
          purchases: [],
        }
      ).purchases,
      showPurchaseHistory: true,
    });
    this.menuClose();
  };
  onTypePopup = ({ target: { value } }) => {
    this.setState({ purchaseCount: value });
  };
  updatePurchaseCount = async (event) => {
    let { product, purchaseCount } = this.state;
    this.setState({ purchaseShow: false });
    const { productPurchase, messageShow } = this.props;
    await productPurchase({
      purchaseCount: purchaseCount,
      productId: this.state.productId,
    });
    messageShow("Purchase count added successfully");
  };
  componentDidMount() {
    this.refresh();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { match } = nextProps;

    this.setState(
      {
        page: match.params.page,
      },
      this.refresh
    );
  }

  refresh = async (isLoading = true) => {
    const { list, messageShow } = this.props;
    const { page, search ,categorysearch,descriptionsearch,isfeaturedsearch ,ispublishedsearch,isstocksearch} = this.state;

    this.isLoadingToggle(isLoading);

    try {
      const { data } = await list({ page, filter: { search ,categorysearch,descriptionsearch,isfeaturedsearch,ispublishedsearch,isstocksearch} });

      if (data.success) {
        this.setState({
          products: data.data.list,
          count: data.data.count,
        });
      } else {
        messageShow(data.message);
      }
    } catch (error) {
      messageShow("There was some error. Please try again.");
    } finally {
      this.isLoadingToggle(false);
    }
  };

  getAllProducts = async (isLoading = true) => {
    this.headerMenuClose();
    const { list, messageShow } = this.props;
    const { page, search,categorysearch,descriptionsearch,isfeaturedsearch,ispublishedsearch,isstocksearch } = this.state;

    this.isLoadingToggle(isLoading);

    try {
      const { data } = await list({ page, filter: { search ,categorysearch,descriptionsearch,isfeaturedsearch,ispublishedsearch,isstocksearch}, listAll: true });

      if (data.success) {
        this.setState({
          productsAll: data.data.list,
          countAll: data.data.count,
        });
        this.onAllProductStatement();
      } else {
        messageShow(data.message);
      }
    } catch (error) {
      messageShow("There was some error. Please try again.");
    } finally {
      this.isLoadingToggle(false);
    }
  };

  isLoadingToggle = (isLoading) => {
    this.setState({
      isLoading,
    });
  };

  onProductStatement = async () => {
    this.headerMenuClose();
    const { productCollection, messageShow } = this.props;
    const { products } = this.state;

    try {
      const { data } = await productCollection({ products });

      messageShow(data.message);

      if (data.success && data.data) {
        window.open(data.data, "_blank");
      }
    } catch (e) {
      messageShow("There was some error. Please try again.");
    }
  };

  onAllProductStatement = async () => {
    this.headerMenuClose();
    const { productCollectionAll, messageShow } = this.props;
    const { productsAll } = this.state;

    try {
      const { data } = await productCollectionAll({ productsAll });

      messageShow(data.message);

      if (data.success && data.data) {
        window.open(data.data, "_blank");
      }
    } catch (e) {
      messageShow("There was some error. Please try again.");
    }
  };

  onDelete = async (productId) => {
    this.menuClose();
    let check = window.confirm("Are you sure you want to delete this product?");

    if (check) {
      const { remove, messageShow } = this.props;

      try {
        const { data } = await remove({ productId });

        messageShow(data.message);

        if (data.success) {
          await this.refresh(false);
        }
      } catch (error) {
        messageShow("Some error occurred. Please try again.");
      }
    }
  };

  onFeatureToggle = async (event, productId) => {
    const isFeatured = event.target.checked;

    let check = window.confirm(
      `Are you sure you want to ${
        isFeatured ? "feature" : "unfeature"
      } this category?`
    );

    if (check) {
      const { featureToggle, messageShow } = this.props;

      try {
        const { data } = await featureToggle({ productId, isFeatured });

        messageShow(data.message);

        if (data.success) {
          await this.refresh(false);
        }
      } catch (error) {
        messageShow("Some error occurred. Please try again.");
      }
    }
  };

  onPublishToggle = async (event, productId) => {
    const isPublished = event.target.checked;

    let check = window.confirm(
      `Are you sure you want to ${
        isPublished ? "publish" : "unpublish"
      } this category?`
    );

    if (check) {
      const { publishToggle, messageShow } = this.props;

      try {
        const { data } = await publishToggle({ productId, isPublished });

        messageShow(data.message);

        if (data.success) {
          await this.refresh(false);
        }
      } catch (error) {
        messageShow("Some error occurred. Please try again.");
      }
    }
  };
  
  onSearch = ({ event, target: { name, value } }) => {  
    const setSearch = (searchType, searchValue) => {
      this.setState({ [searchType]: searchValue }, () => {
        if (searchValue === "") {
          const { history } = this.props;
          history.push(routes.productList.path(1));
        } else {
          this.changeSearch();
        }
      });
    };
    setSearch(name, value);
  };

  oncSearch = ({ event, target: { name, value, checked } }) => {
    const handleCheckboxChange = (stateProp) => {
      this.setState({ [stateProp]: checked }, () => {
        if (value === "") {
          const { history } = this.props;
          history.push(routes.productList.path(1));
        } else {
          this.changeSearch();
        }
      });
    };
    switch (name) {
      case "isfeaturedsearch":
        handleCheckboxChange("isfeaturedsearch");
        break;
  
      case "ispublishedsearch":
        handleCheckboxChange("ispublishedsearch");
        break;
  
      case "isstocksearch":
        handleCheckboxChange("isstocksearch");
        break;
  
      default:
        break;
    }
  };

  menuClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  menuClose = () => {
    this.setState({ anchorEl: null });
  };

  headerMenuClick = (event) => {
    this.setState({ hederMenuEl: event.currentTarget });
  };
  headerMenuClose = () => {
    this.setState({ hederMenuEl: null });
  };

  // On reset current stocks
  resetStocks = async () => {
    this.headerMenuClose()
    try {
      const { onResetStocks, messageShow } = this.props;
      const result = await onResetStocks();
      messageShow(result?.data?.message);
    } catch (error) {
      messageShow("There was some error. Please try again.");
    }
  };

  render() {
    const {
      auth: { user },
      classes,
    } = this.props;
    const { isLoading, products, count, search ,categorysearch,descriptionsearch,isfeaturedsearch,ispublishedsearch,isstocksearch} = this.state;

    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit">
            Products
          </Typography>

          <div className={classes.grow}>
           <InputBase
           type={"search"}
           name={"search"}
           value={search}
           placeholder={'Search Products'}
           className={classes.search}
           onChange={this.onSearch}
           autoFocus
           />
          </div>
          <Typography variant="h6" color="inherit">
          Description
          </Typography>
          <div className={classes.grow}>
            <InputBase
              type={"descriptionsearch"}
              name={"descriptionsearch"}
              value={descriptionsearch}
              placeholder={'Search Description'}
              className={classes.search}
              onChange={this.onSearch}
              autoFocus
              style={{ width: '150px' }}
            />
          </div>

          <Typography variant="h6" color="inherit">
            Category
          </Typography>

          <div className={classes.grow}>
            <InputBase
              type={"categorysearch"}
              name={"categorysearch"}
              value={categorysearch}
              placeholder={'Search Category'}
              className={classes.search}
              onChange={this.onSearch}
              autoFocus
              style={{ width: '150px' }}
            />
          </div>

          <Link to={routes.productCreate.path}>
            <Button color="inherit">Create</Button>
          </Link>
          <Link to={routes.productBulkCreate.path}>
            <Button color="inherit">Bulk Create</Button>
          </Link>
          <Button
            variant="outline"
            color="secondary"
            endIcon={<ArrowDropDownIcon />}
            id="basic-button"
            size="medium"
            aria-controls={
              Boolean(this.state.hederMenuEl) ? "basic-menu" : undefined
            }
            aria-haspopup="true"
            aria-expanded={Boolean(this.state.hederMenuEl) ? "true" : undefined}
            onClick={this.headerMenuClick}
          >
            Advance
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={this.state.hederMenuEl}
            open={Boolean(this.state.hederMenuEl)}
            onClose={this.headerMenuClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            elevation={1}
          >
            <MenuItem onClick={this.onProductStatement}><Download style={{ marginRight: 10 }} />
            Download Product</MenuItem>
            <MenuItem onClick={this.getAllProducts}><Download style={{ marginRight: 10 }} />
            Download All Products</MenuItem>
            <MenuItem onClick={this.resetStocks}><RestartAlt style={{ marginRight: 10 }} />Restore Stocks</MenuItem>
          </Menu>
          {/* <Button variant="outlined" onClick={this.onProductStatement}>
            <IconCloudDownload style={{ marginRight: 10 }} />
            Download Product
          </Button> */}
          {/* <Button
            style={{ marginLeft: 2 }}
            variant="outlined"
            onClick={this.getAllProducts}
          >
            <IconCloudDownload style={{ marginRight: 10 }} />
            Download All Products
          </Button> */}
          {/* <Button
            style={{ marginLeft: 2 }}
            variant="outlined"
            onClick={this.resetStocks}
          >
            Reset Stocks
          </Button> */}
        </Toolbar>

        <SectionPaper>
          {isLoading ? (
            <Loading />
          ) : products.length === 0 ? (
            <EmptyMessage message={"You have not added any products yet."} />
          ) : (
            <>
              <Table padding={"normal"}>
                <TableHead>
                  <TableRow>
                    <TableCell width={85}>Image</TableCell>
                    <TableCell>Name</TableCell>
                    {/*{
                          user.role === params.user.roles.admin.key &&
                          <TableCell>Vendor</TableCell>
                        } */}
                    <TableCell>Category</TableCell> 
                    {/* <TableCell>Buys</TableCell> */}
                    <TableCell width={160} align="center">
                    <Checkbox
                          name='isstocksearch'
                          checked={isstocksearch}
                          onChange={(event) =>
                          this.oncSearch(event )
                          }
                          color="primary"
                         />
                         Out of Stock
                    </TableCell>
                    {/* <TableCell>Subscribable</TableCell> */}
                    <TableCell width={180}>Updated</TableCell>
                    {user.role === params.user.roles.admin.key ? (
                      <>
                        <TableCell width={150} align="center">
                        <Checkbox
                          name='isfeaturedsearch'
                          checked={isfeaturedsearch}
                          onChange={(event) =>
                          this.oncSearch(event )
                          }
                          color="primary"
                         />
                         Featured
                        </TableCell>
                         <TableCell width={140} align="center">
                          <Checkbox
                          name='ispublishedsearch'
                          checked={ispublishedsearch}
                          onChange={(event) =>
                          this.oncSearch(event )
                          }
                          color="primary"
                         />
                         Published
                        </TableCell>
                      </>
                    ) : (
                      <TableCell width={80} align="center">
                        Published
                      </TableCell>
                    )}
                    <TableCell
                      width={
                        user.role === params.user.roles.admin.key ? 180 : 90
                      }
                      align="center"
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {products.map(
                    ({
                      _id,
                      image,
                      name,
                      vendorId,
                      categoryId,
                      buyCount,
                      currentStock,
                      canSubscribe,
                      isFeatured,
                      isPublished,
                      updatedAt,
                    }, prodIndex) => (
                      <TableRow key={_id}>
                        <TableCell>
                          <Link to={routes.productEdit.path(_id)}>
                            <Image
                              src={getImageSource(`product/${image}`)}
                              defaultSrc={getImageSource()}
                            />
                          </Link>
                        </TableCell>
                        <TableCell>{subString(name, 50)}</TableCell>
                        {/* <TableCell>{subString(name, 30)}</TableCell>
                            
                              user.role === params.user.roles.admin.key &&
                              <TableCell>{vendorId?.name}</TableCell>
                            } */}
                        <TableCell>{categoryId.name}</TableCell>
                        {/* <TableCell>{buyCount || 0}</TableCell> */}
                        <TableCell align="center" >{currentStock || 0}</TableCell>
                        {/* <TableCell>{canSubscribe ? 'Yes' : 'No'}</TableCell> */}
                        <TableCell>
                          {dateFormat(
                            dateParseISO(updatedAt),
                            params.date.format.full
                          )}
                        </TableCell>

                        {user.role === params.user.roles.admin.key ? (
                          <>
                            <TableCell align="center">
                              <Checkbox
                                checked={isFeatured}
                                onChange={(event) =>
                                  this.onFeatureToggle(event, _id)
                                }
                              />
                            </TableCell>
                            <TableCell align="center">
                              <Checkbox
                                checked={isPublished}
                                onChange={(event) =>
                                  this.onPublishToggle(event, _id)
                                }
                                color="primary"
                              />
                            </TableCell>
                          </>
                        ) : (
                          <TableCell>{isPublished ? "Yes" : "No"}</TableCell>
                        )}
                        <TableCell align="center">
                          {/* <Button onClick={() => this.handleShowHistory(_id)}>
                            Purchase Report
                          </Button>
                          <Button onClick={() => this.handleShow(_id)}>
                            Purchase Order
                          </Button> */}
                          <Button
                            color="secondary"
                            component={Link}
                            to={routes.productEdit.path(_id)}
                          >
                            Edit
                          </Button>
                          {user.role === params.user.roles.admin.key && (
                            <Button color="error" onClick={() => this.onDelete(_id)}>
                              Delete
                            </Button>
                          )}
                            {/* <div>
                            <IconButton
                              size="large"
                              aria-controls={
                                Boolean(this.state.anchorEl)
                                  ? "basic-menu"
                                  : undefined
                              }
                              color="secondary"
                              aria-haspopup="true"
                              aria-expanded={
                                Boolean(this.state.anchorEl)
                                  ? "true"
                                  : undefined
                              }
                              onClick={(prodIndex, event) => this.menuClick(prodIndex, event)}
                            >
                              <MoreVert />
                            </IconButton>
                            <Menu
                              anchorEl={this.state.anchorEl}
                              open={Boolean(this.state.anchorEl)}
                              onClose={this.menuClose}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                              elevation={1}
                            >
                              <MenuItem>{_id}</MenuItem>
                              <MenuItem
                                onClick={() => this.handleShowHistory(_id)}
                              >
                                Purchase Report - {_id}
                              </MenuItem>
                              <MenuItem onClick={() => this.handleShow(_id)}>
                                Purchase Order - {_id}
                              </MenuItem>
                              <MenuItem
                                component={Link}
                                to={routes.productEdit.path(_id)}
                              >
                                Edit - {_id}
                              </MenuItem>
                              {user.role === params.user.roles.admin.key && (
                                <MenuItem onClick={() => this.onDelete(_id)}>
                                  Delete - {_id}
                                </MenuItem>
                              )}
                            </Menu>
                            </div> */}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>

              <Pagination count={count} route={routes.productList} />
            </>
          )}
          <Dialog
            onClose={this.handleClose}
            open={this.state.purchaseShow ?? false}
            aria-labelledby="simple-dialog-title"
            fullWidth={true}
          >
            <DialogTitle id="simple-dialog-title">
              Purchase Addition
            </DialogTitle>
            <DialogContent>
              <div>
                <Grid container spacing={10} className={classes.buttonUpload}>
                  <Grid item xs={7}>
                    {/* Input - Unit */}
                    <TextField
                      name={"purchase"}
                      value={this.state.purchaseCount}
                      onChange={this.onTypePopup}
                      label={"Purchase Count"}
                      placeholder={"Purchase Count (eg: 100)"}
                      required={true}
                      type="number"
                      margin={"dense"}
                      autoComplete={"off"}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    {/* Input - Can Subscribe */}
                    <Button
                      variant="primary"
                      onClick={this.updatePurchaseCount}
                    >
                      Purchase
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </DialogContent>
          </Dialog>
          <Dialog
            onClose={this.handleClose}
            open={this.state.showPurchaseHistory ?? false}
            aria-labelledby="simple-dialog-title"
            fullWidth={true}
          >
            <DialogTitle id="simple-dialog-title">Purchase History</DialogTitle>
            <DialogContent>
              <Table padding="normal">
                <TableHead>
                  <TableRow>
                    <TableCell>Purchase Count</TableCell>
                    <TableCell>Updated Timestamp</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.purchases &&
                    this.state.purchases.map(
                      ({ purchaseCount, updatedAt }, _id) => (
                        <TableRow key={_id}>
                          <TableCell>{purchaseCount}</TableCell>
                          <TableCell>{updatedAt}</TableCell>
                        </TableRow>
                      )
                    )}
                </TableBody>
              </Table>
            </DialogContent>
          </Dialog>
        </SectionPaper>
      </div>
    );
  }
}

// Component Properties
List.propTypes = {
  auth: PropTypes.object.isRequired,
  list: PropTypes.func.isRequired,
  productCollection: PropTypes.func.isRequired,
  productCollectionAll: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  featureToggle: PropTypes.func.isRequired,
  publishToggle: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  onResetStocks: PropTypes.func.isRequired,
};

// Component State
function listState(state) {
  return {
    auth: state.auth,
  };
}

export default connect(listState, {
  list,
  remove,
  productCollection,
  productPurchase,
  productCollectionAll,
  featureToggle,
  publishToggle,
  messageShow,
  onResetStocks,
})(withStyles(styles)(List));
