// Component Styles
const styles = theme => ({
    toolbar: {
        borderBottom: `1px solid rgba(0, 0, 0, 0.12)`
    },

    menuButton: {
        marginLeft: -12
    },

    buttonsContainer: {
        textAlign: 'right',
        marginTop: theme.spacing()
    },

    buttonUpload: {
        marginTop: theme.spacing(2.5)
    },

    select: {
        marginTop: theme.spacing(),
    }
})

export default styles
