// Imports
import { withStyles } from '@material-ui/core'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import React, { PureComponent , useState }  from 'react'
import { connect } from 'react-redux'
import { messageShow } from '../../common/api/actions'
import SectionPaper from '../../common/SectionPaper'
import styles from './styles'
import 'react-datepicker/dist/react-datepicker.css';
import InputBase from '@material-ui/core/InputBase'
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";




// Component
class Leaderboard extends PureComponent {
  
  render() {
    const {
      classes
    } = this.props;
    const defaultValue = 'select';
    return (
      
      <>
      <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            Search By :
          </Typography>
          <InputBase placeholder={'<User name>'}></InputBase>
          <InputBase placeholder={'<Deals'}></InputBase>
          <InputBase placeholder={'<Mobile>'}></InputBase>
        </Toolbar>
        <SectionPaper>
                 <TableHead>
                  <TableRow>
                  <TableCell style={{  width: 150 }}>User name</TableCell>
                  <TableCell style={{  width: 100 }}>RANK</TableCell>
                  <TableCell style={{  width: 150 }}>Mobile</TableCell> 
                  <TableCell style={{  width: 100 }}>Earned Points</TableCell>
                  <TableCell style={{  width: 100 }}>Balance</TableCell> 
                  <TableCell style={{  width: 150 }}>Available Deals</TableCell> 
                  <TableCell style={{  width: 150 }}>Available Deals</TableCell> 
                  <TableCell style={{  width: 150 }}>Redeemed online Deals</TableCell> 
                  <TableCell style={{  width: 150 }}>Offline Deals to Approve</TableCell> 
                    
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                  <TableCell style={{  width: 150 }}>
                  <InputBase placeholder={'User Name'}></InputBase>
                    </TableCell>
                  <TableCell style={{  width: 100 }}>
                    <InputBase placeholder={'Rank'}></InputBase>
                  </TableCell>
                  </TableRow>
                </TableBody>
               
      </SectionPaper>
      </>
    )
  }
}

export default connect(null, {  messageShow  })(withStyles(styles)(Leaderboard))
