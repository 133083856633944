// Imports
import React from "react";
import ReactDOM from "react-dom";
import { Provider as StateProvider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import isFunction from "lodash/isFunction";

// App Imports
import { store } from "./setup/store";
import routes from "./setup/routes";
import { setUser, setUserLocally } from "./modules/user/api/actions/query";
import Layout from "./modules/common/Layout";
import Redirector from "./modules/common/Redirector";
import RoutePrivate from "./modules/auth/RoutePrivate";
import * as serviceWorker from "./serviceWorker";

import { connectToWebSocket } from "./setup/apiWebSocket";

// Latest UI Library changes
import { ThemeProvider, createTheme } from "@mui/material/styles";
import theme from "./setup/theme";

//--- Date Pickers Providers
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import "./assets/css/bootstrap.min.css";

// User Authentication
const token = window.localStorage.getItem("token");
if (token && token !== "undefined" && token !== "") {
  const user = JSON.parse(window.localStorage.getItem("user"));
  if (user) {
    // Dispatch action
    store.dispatch(setUser(token, user));

    setUserLocally(token, user);

    if (window.WebSocket) {
      connectToWebSocket(store);
    } else {
      console.log("WebSocket is not supported by your browser.");
    }
  }
}

// Render App
ReactDOM.render(
  <StateProvider store={store}>
    <Router>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Layout>
            <Switch>
              {Object.values(routes).map((route, index) =>
                route.auth ? (
                  <RoutePrivate
                    {...route}
                    key={index}
                    path={isFunction(route.path) ? route.path() : route.path}
                  />
                ) : (
                  <Route
                    {...route}
                    key={index}
                    path={isFunction(route.path) ? route.path() : route.path}
                  />
                )
              )}

              <Route component={Redirector} />
            </Switch>
          </Layout>
        </LocalizationProvider>
      </ThemeProvider>
    </Router>
  </StateProvider>,
  document.getElementById("root")
);

// Service Worker
serviceWorker.unregister();
