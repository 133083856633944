// Imports
import axios from 'axios'
// App Imports
import { API_URL } from '../../../../setup/config/env'


// Actions

// Save
export function save(feedbacks) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'feedbackCreateOrUpdate',
      params: feedbacks
    })
  }
}

//remove
export function remove(feedbackId) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'feedbackDelete',
      params: feedbackId
    })
  }
}
