// Imports
import React from 'react'
import PropTypes from 'prop-types'

// Component
const Image = ({ src, defaultSrc, size, alt }) => (
  <img
    src={src}
    style={size === '100%' ? { width: size } : { width: size, height: 'auto' }}
    onError={e => e.target.src = defaultSrc}
    alt={alt}
  />
)

// Component Properties
Image.propTypes = {
  src: PropTypes.string.isRequired,
  defaultSrc: PropTypes.string,
  size: PropTypes.any,
  alt: PropTypes.string,
}
Image.defaultProps = {
  size: 40,
  alt: ''
}

export default Image

