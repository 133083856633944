// App Imports
import categories from './list'
import categoriesParent from './listParent'
import categoriesByParent from './listByParent'

export default {
  categories,
  categoriesParent,
  categoriesByParent,
}
