// App Imports
import { AREA_LIST_DONE, AREA_LIST_REQUEST, AREA_LIST_RESET, AREA_LIST_RESPONSE } from '../actions/types'

// List - Areas

// Initial State
const areasInitialState = {
    isLoading: false,
    arealist: []
}

// State
export default (state = areasInitialState, action) => {
    switch (action.type) {
        case AREA_LIST_REQUEST:
            return {
                ...state,
                isLoading: action.isLoading
            }

        case AREA_LIST_RESPONSE:
            return {
                ...state,
                arealist: action.arealist
            }

        case AREA_LIST_DONE:
            return {
                ...state,
                isLoading: false
            }

        case AREA_LIST_RESET:
            return { ...areasInitialState }

        default:
            return state
    }
}
