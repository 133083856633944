// Imports
import axios from 'axios'
// App Imports
import { API_URL } from '../../../../setup/config/env'
import { MESSAGE_SHOW } from '../../../common/api/actions'
import { listVendors } from '../../../user/api/actions/query'
import { AREA_LIST_CACHE, PINCODE_LIST_CACHE } from './cache-keys'
import { AREA_LIST_DONE, AREA_LIST_REQUEST, AREA_LIST_RESPONSE, LIST_DONE, LIST_REQUEST, LIST_RESPONSE } from './types'


// Actions

// Get list
export function list(isLoading = true) {
  return async dispatch => {
    // Caching
    try {
      const list = JSON.parse(window.localStorage.getItem(PINCODE_LIST_CACHE))

      if (list) {
        dispatch({
          type: LIST_RESPONSE,
          list
        })
      } else {
        dispatch({
          type: LIST_REQUEST,
          isLoading
        })
      }
    } catch (e) {
      dispatch({
        type: LIST_REQUEST,
        isLoading
      })
    }

    try {
      const { data } = await axios.post(API_URL, {
        operation: 'pincodeList'
      })

      if (!data.success) {
        dispatch({
          type: MESSAGE_SHOW,
          success: data.success,
          message: data.message
        })
      } else {
        const list = data.data;
        if (list) {
          dispatch({
            type: LIST_RESPONSE,
            list
          })
          window.localStorage.setItem(PINCODE_LIST_CACHE, JSON.stringify(list))
        }
      }
    } catch (error) {
      dispatch({
        type: MESSAGE_SHOW,
        success: false,
        message: error.message
      })
    } finally {
      dispatch({
        type: LIST_DONE,
        isLoading: false
      })
    }
  }
}

// Get areas list
export function arealist(pincodeId, isLoading = true) {
  return async dispatch => {
    // Caching
    try {
      const arealist = JSON.parse(window.localStorage.getItem(AREA_LIST_CACHE))

      if (arealist) {
        dispatch({
          type: AREA_LIST_RESPONSE,
          arealist
        })
      } else {
        dispatch({
          type: AREA_LIST_REQUEST,
          isLoading
        })
      }
    } catch (e) {
      dispatch({
        type: AREA_LIST_REQUEST,
        isLoading
      })
    }

    try {
      const { data } = await axios.post(API_URL, {
        operation: 'areaList',
        params: { pincodeId }
      })

      if (!data.success) {
        dispatch({
          type: MESSAGE_SHOW,
          success: data.success,
          message: data.message
        })
      } else {
        const arealist = data.data

        dispatch({
          type: AREA_LIST_RESPONSE,
          arealist
        })

        window.localStorage.setItem(AREA_LIST_CACHE, JSON.stringify(arealist))
      }
    } catch (error) {
      dispatch({
        type: MESSAGE_SHOW,
        success: false,
        message: error.message
      })
    } finally {
      dispatch({
        type: AREA_LIST_DONE,
        isLoading: false
      })
    }
  }
}



// Get detail
export function detail({ pincodeId }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'pincodeDetail',
      params: { pincodeId }
    })
  }
}

// Get Area detail
export function areaDetail({ areaId }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'areaDetail',
      params: { areaId }
    })
  }
}
