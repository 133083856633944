// App Imports
import params from '../../../setup/config/params'
import List from '../List'
import Detail from '../Detail'

// Pages routes
export default {
  orderSearch: {
    path: (page = ':page') => `/orders/${ page }`,
    activePath: '/order',
    component: List,
    auth: true,
    role: [params.user.roles.admin.key, params.user.roles.vendor.key]
  },

  orderList: {
    path: (page = ':page') => `/orders/${ page }`,
    activePath: '/order',
    component: List,
    auth: true,
    role: [params.user.roles.admin.key, params.user.roles.vendor.key]
  },

  orderDetail: {
    path: (orderId = ':orderId') => (`/order/detail/${ orderId }`),
    component: Detail,
    auth: true,
    role: [params.user.roles.admin.key, params.user.roles.vendor.key]
  }
}
