// Imports
import axios from 'axios'
// App Imports
import { API_URL } from '../../../../setup/config/env'
import { MESSAGE_SHOW } from '../../../common/api/actions'
import { DELIVERYBOY_LIST_BY_PARENT_CACHE, DELIVERYBOY_LIST_CACHE, DELIVERYBOY_LIST_PARENT_CACHE } from './cache-keys'
import {
    LIST_BY_PARENT_DONE, LIST_BY_PARENT_REQUEST, LIST_BY_PARENT_RESPONSE, LIST_DONE,
    LIST_PARENT_DONE, LIST_PARENT_REQUEST, LIST_PARENT_RESPONSE, LIST_REQUEST, LIST_RESPONSE
} from './types'


// Actions

// Get list
export function list(isLoading = true) {
  return async dispatch => {
    // Caching
    try {
      const list = JSON.parse(window.localStorage.getItem(DELIVERYBOY_LIST_CACHE))

      if(list) {
        dispatch({
          type: LIST_RESPONSE,
          list
        })
      } else {
        dispatch({
          type: LIST_REQUEST,
          isLoading
        })
      }
    } catch(e) {
      dispatch({
        type: LIST_REQUEST,
        isLoading
      })
    }

    try {
      const { data } = await axios.post(API_URL, {
        operation: 'deliveryBoyListAll'
      })

      if(!data.success) {
        dispatch({
          type: MESSAGE_SHOW,
          success: data.success,
          message: data.message
        })
      } else {
        const list = data.data

        dispatch({
          type: LIST_RESPONSE,
          list
        })

        window.localStorage.setItem(DELIVERYBOY_LIST_CACHE, JSON.stringify(list))
      }
    } catch(error) {
      dispatch({
        type: MESSAGE_SHOW,
        success: false,
        message: error.message
      })
    } finally {
      dispatch({
        type: LIST_DONE,
        isLoading: false
      })
    }
  }
}

// Get parent list
export function listParent(isLoading = true) {
  return async dispatch => {
    // Caching
    try {
      const list = JSON.parse(window.localStorage.getItem(DELIVERYBOY_LIST_PARENT_CACHE))

      if(list) {
        dispatch({
          type: LIST_PARENT_RESPONSE,
          list
        })
      } else {
        dispatch({
          type: LIST_PARENT_REQUEST,
          isLoading
        })
      }
    } catch(e) {
      dispatch({
        type: LIST_PARENT_REQUEST,
        isLoading
      })
    }

    try {
      const { data } = await axios.post(API_URL, {
        operation: 'deliveryBoyListParent'
      })

      if(!data.success) {
        dispatch({
          type: MESSAGE_SHOW,
          success: data.success,
          message: data.message
        })
      } else {
        const list = data.data

        dispatch({
          type: LIST_PARENT_RESPONSE,
          list
        })

        window.localStorage.setItem(DELIVERYBOY_LIST_PARENT_CACHE, JSON.stringify(list))
      }
    } catch(error) {
      dispatch({
        type: MESSAGE_SHOW,
        success: false,
        message: error.message
      })
    } finally {
      dispatch({
        type: LIST_PARENT_DONE,
        isLoading: false
      })
    }
  }
}

// Get list by parent deliveryBoy
export function listByParent(parentId, isLoading = true) {
  return async dispatch => {
    // Caching
    const CACHE_KEY = DELIVERYBOY_LIST_BY_PARENT_CACHE + parentId

    try {
      const list = JSON.parse(window.localStorage.getItem(CACHE_KEY))

      if(list) {
        dispatch({
          type: LIST_BY_PARENT_RESPONSE,
          list
        })
      } else {
        dispatch({
          type: LIST_BY_PARENT_REQUEST,
          isLoading
        })
      }
    } catch(e) {
      dispatch({
        type: LIST_BY_PARENT_REQUEST,
        isLoading
      })
    }

    try {
      const { data } = await axios.post(API_URL, {
        operation: 'deliveryBoyListByParent',
        params: { parentId }
      })

      if(!data.success) {
        dispatch({
          type: MESSAGE_SHOW,
          success: data.success,
          message: data.message
        })
      } else {
        const list = data.data

        dispatch({
          type: LIST_BY_PARENT_RESPONSE,
          list
        })

        window.localStorage.setItem(CACHE_KEY, JSON.stringify(list))
      }
    } catch(error) {
      dispatch({
        type: MESSAGE_SHOW,
        success: false,
        message: error.message
      })
    } finally {
      dispatch({
        type: LIST_BY_PARENT_DONE,
        isLoading: false
      })
    }
  }
}

// Get detail
export function detail({ deliveryBoyId }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'deliveryBoyDetail',
      params: { deliveryBoyId }
    })
  }
}

// Get count
export function getCount() {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'deliveryBoyCount'
    })
  }
}
