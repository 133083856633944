// Imports
import { withStyles } from '@material-ui/core'
import Anchor from '@mui/material/Link'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
// UI Imports
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import dateFormat from 'date-fns/format'
import dateParseISO from 'date-fns/parseISO'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
// App Imports
import params from '../../../setup/config/params'
import { messageShow } from '../../common/api/actions'
import EmptyMessage from '../../common/EmptyMessage'
import Image from '../../common/Image'
import Loading from '../../common/Loading'
import Pagination from '../../common/Pagination'
import SectionPaper from '../../common/SectionPaper'
import { listCustomers } from '../../user/api/actions/query'
import { getImageSource } from '../../user/api/routes'
import routes from '../api/routes'
import styles from './styles'



// Component
class List extends PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      customers: [],
      count: 0
    }
  }

  componentDidMount() {
    const { match } = this.props

    this.refresh(match.params.page)()
  }

  componentWillReceiveProps(nextProps) {
    const { match } = nextProps

    this.refresh(match.params.page)()
  }

  refresh = (page = 1, isLoading = true) => async () => {
    const { listCustomers, messageShow } = this.props

    this.isLoadingToggle(isLoading)

    try {
      const { data } = await listCustomers({ page })

      if(data.success) {
        this.setState({
          customers: data.data.list,
          count: data.data.count
        })
      } else {
        messageShow(data.message)
      }
    } catch(error) {
      messageShow('There was some error. Please try again.')
    } finally {
      this.isLoadingToggle(false)
    }
  }

  isLoadingToggle = isLoading => {
    this.setState({
      isLoading
    })
  }

  render() {
    const { classes } = this.props
    const { isLoading, customers, count } = this.state

    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            Customers
          </Typography>
        </Toolbar>

        <SectionPaper>
          {
            isLoading
              ? <Loading />
              : customers.length === 0
                ? <EmptyMessage message={'No customers to show.'} />
                : <>
                    <Table padding={"normal"}>
                      <TableHead>
                        <TableRow>
                          <TableCell width={85}>Image</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Mobile</TableCell>
                          {/*<TableCell>Pincode / Address</TableCell>*/}
                          <TableCell>Gender</TableCell>
                          {/*<TableCell>DoB</TableCell>*/}
                          <TableCell width={180}>Joined</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {
                          customers.map(({ _id, image, name, email, mobile, pincodeId, dateOfBirth, addressFlat, addressWing, gender, createdAt }) =>
                            <TableRow key={_id}>
                              <TableCell>
                                <Link to={routes.customerDetail.path(_id)}>
                                  <Image
                                    src={getImageSource(image)}
                                    defaultSrc={getImageSource()}
                                  />
                                </Link>
                              </TableCell>
                              <TableCell>
                                <Link to={routes.customerDetail.path(_id)}>
                                  <Anchor component="span" style={{ fontWeight: 500 }} color={'primary'}>{ name }</Anchor>
                                </Link>
                              </TableCell>
                              <TableCell>{ email.toLowerCase() }</TableCell>
                              <TableCell>{ mobile }</TableCell>
                              {/*<TableCell>{ pincodeId && pincodeId.pincode } { addressFlat && addressWing && `, ${ addressWing }, ${ addressFlat }` }</TableCell>*/}
                              <TableCell>{ gender && gender.toUpperCase() }</TableCell>
                              {/*<TableCell>{ dateOfBirth }</TableCell>*/}
                              <TableCell>{ dateFormat(dateParseISO(createdAt), params.date.format.full) }</TableCell>
                            </TableRow>
                          )
                        }
                      </TableBody>
                    </Table>

                    <Pagination
                      count={count}
                      route={routes.customerList}
                    />
                  </>
          }
        </SectionPaper>
      </div>
    )
  }
}

// Component Properties
List.propTypes = {
  listCustomers: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
}

export default connect(null, { listCustomers, messageShow })(withStyles(styles)(List))
