// Imports
import { applyMiddleware, combineReducers, createStore } from 'redux'
import thunk from 'redux-thunk'
import pincode from '../modules/pincode/api/state'
import banner from '../modules/banner/api/state'
import category from '../modules/category/api/state'
// App Imports
import common from '../modules/common/api/state'
import delivery from '../modules/delivery/api/state'
import user from '../modules/user/api/state'


// Root Reducer
const rootReducer = combineReducers({
  common,
  ...user,
  ...category,
  ...pincode,
  ...banner,
  ...delivery,
})

// Store
export const store = createStore(rootReducer, applyMiddleware(thunk))
