// Imports
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styles from './styles'

// UI Imports
import Grid from '@material-ui/core/Grid/Grid'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles/index'
import Typography from '@material-ui/core/Typography'
import Button from '@mui/material/Button'
import Toolbar from '@material-ui/core/Toolbar'
import IconClose from '@material-ui/icons/Close'
import CheckIcon from '@mui/icons-material/Check';
import IconArrowBack from '@material-ui/icons/ArrowBack'
import IconButton from '@material-ui/core/IconButton'

// App Imports
import { store } from '../../../../setup/store'
import { nullToEmptyString, capitalizeFirstLetter } from '../../../../setup/helpers'
import SectionPaper from '../../../common/SectionPaper'
import Loading from '../../../common/Loading'
import Image from '../../../common/Image'
import { updateDetail } from '../../api/actions/mutation'
import { setUser } from '../../api/actions/query'
import { upload, messageShow } from '../../../common/api/actions'
import routes, { imageUser } from '../../api/routes'

class Update extends Component {

  constructor(props) {
    super(props)

    this.state = {
      user: {
        name: '',
        email: '',
        mobile: '',
        image: '',
      },
      isLoading: false,
      isLoadingSubmit: false,
      formData: {
        name: '',
        email: '',
        mobile: '',
        image: '',
      }
    }
  }


  componentDidMount() {        
    this.getDetails()
  }

  getDetails = async () => {
    const { auth } = this.props

    try {
      this.setState({
        user: auth.user,
        formData: JSON.parse(JSON.stringify(auth.user))
      })
    } catch (error) {
      messageShow('Some error occurred. Please try again.')
    } finally {
      this.isLoadingToggle(false)
    }
  }

  isLoadingToggle = (isLoading) => {
    this.setState({
      isLoading
    })
  }

  onType = ({ target: { name, value } }) => {
    const { formData } = this.state
    
    if (name === 'email') {
      formData[name] = value
    } else {
      formData[name] = capitalizeFirstLetter(value)
    }

    this.setState({ formData })
  }

  onUpload = async event => {
    const { upload, messageShow } = this.props
    
    messageShow('Uploading file, please wait...')

    let file = new FormData()
    file.append('type', 'product')
    file.append('file', event.target.files[0])

    // Upload image
    try {
      const { data } = await upload(file)

      if (data.success) {
        messageShow('File uploaded successfully.')

        const { formData } = this.state

        setTimeout(() => {
          formData.image = data.file
          this.setState({
            formData
          })
        }, 1500)

      } else {
        messageShow('There was some error. Please try again.')
      }
    } catch (error) {
      messageShow('There was some error. Please try again.')
    }
  }

  // Form Submit
  submit = async event => {
    event.preventDefault()

    const { formData } = this.state
    const { updateDetail, updateUser, messageShow, history } = this.props

    this.isLoadingSubmitToggle(true)

    try {
      const { data } = await updateDetail({ user: formData })

      this.setState({
        user: formData
      })

      this.isLoadingSubmitToggle(false)

      messageShow("Profile Details update Successfully")

      if (data.success) {
        // User Authentication
        const token = window.localStorage.getItem('token')
        if (token && token !== 'undefined' && token !== '') {
          const user = JSON.parse(window.localStorage.getItem('user'))
          if (user) {
            // Dispatch action
            store.dispatch(setUser(token, user))
          }
        }
        history.goBack()
      }
    } catch {

    }
  }

  isLoadingSubmitToggle = (isLoadingSubmit) => {
    this.setState({ isLoadingSubmit })
  }

  render() {
    const { isLoading, isLoadingSubmit, formData } = this.state
    return (
      <div>
        <Toolbar className={styles.toolbar}>
          <Link to={routes.userProfile.path}>
            <IconButton color="inherit">
              <IconArrowBack />
            </IconButton>
          </Link>

          <Typography variant="h6" color="inherit" >
            Edit Profile
          </Typography>
        </Toolbar>
        <Grid item xs={12} lg={6}>
          <SectionPaper padding>
            {
              isLoading
                ? <Loading />
                : <form onSubmit={this.submit}>
                  {/* Input - Name */}
                  <Grid item xs={12}>
                    <TextField
                      name={'name'}
                      value={nullToEmptyString(formData.name)}
                      onChange={this.onType}
                      label={'Name'}
                      placeholder={'Enter customer name'}
                      margin={'dense'}
                      autoComplete={'off'}
                      fullWidth
                      autoFocus
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      name={'email'}
                      value={nullToEmptyString(formData.email.toLowerCase())}
                      onChange={this.onType}
                      label={'Email'}
                      placeholder={'Enter Email'}
                      margin={'dense'}
                      autoComplete={'off'}
                      fullWidth
                      autoFocus
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      name={'mobile'}
                      value={nullToEmptyString(formData.mobile)}
                      onChange={this.onType}
                      label={'Mobile'}
                      placeholder={'Enter Email'}
                      margin={'dense'}
                      autoComplete={'off'}
                      fullWidth
                      autoFocus
                    />
                  </Grid>

                  <Grid item sm={6} style={{ paddingTop: '30px'}}>
                    <div style={{ marginBottom: 20 }}>
                      <input
                        accept={'image/png,image/jpeg'}
                        style={{ display: 'none' }}
                        id={'contained-button-file'}
                        type={'file'}
                        onChange={this.onUpload}
                      />

                      <label htmlFor={'contained-button-file'}>
                        <Button
                          variant={'outlined'}
                          component={'span'}
                          type={'file'}
                          fullWidth
                        >
                          Upload Image
                        </Button>
                      </label>
                    </div>

                    <a href={'#'} target={'_blank'}>
                      <Image
                        src={formData.image}
                        defaultSrc={imageUser()}
                        size={150}
                      />
                    </a>
                  </Grid>

                  <Grid item xs={12} style={{ textAlign: 'right', marginTop: '20px' }}>
                    <Link to={routes.userProfile.path}>
                      <IconButton
                        type={'button'}
                        aria-label={'Close'}
                      >
                        <IconClose />
                      </IconButton>
                    </Link>

                    <IconButton
                      type={'submit'}
                      aria-label={'Save'}
                      color={'primary'}
                      disabled={isLoadingSubmit}
                    >
                      <CheckIcon/>
                    </IconButton>
                  </Grid>
                </form>
            }
          </SectionPaper>
        </Grid>
      </div>
    )
  }
}

// Component Properties
Update.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  updateDetail: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired
}

// Component State
function profileState(state) {
  return {
    auth: state.auth
  }
}

export default connect(profileState, { upload, messageShow, updateDetail, setUser })(withStyles(styles)(Update))