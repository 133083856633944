// Imports
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { withStyles } from '@material-ui/core/styles'
// UI Imports
import Typography from '@material-ui/core/Typography'
import IconBarChart from '@material-ui/icons/BarChart'
import IconCategoryOutlined from '@material-ui/icons/CategoryOutlined'
import IconDashboardOutlined from '@material-ui/icons/DashboardOutlined'
import IconFilterNoneOutlined from '@material-ui/icons/FilterNoneOutlined'
import IconLocationCityOutlined from '@material-ui/icons/LocationCityOutlined'
import IconDirectionsBikeOutlined from '@material-ui/icons/DirectionsBikeOutlined';
import IconForumOutlined from '@material-ui/icons/ForumOutlined';
import GiftIcon from '@material-ui/icons/CardGiftcard';
import IconPeople from '@material-ui/icons/People'
import IconPeopleOutline from '@material-ui/icons/PeopleOutline'
import IconPhotoLibraryOutlined from '@material-ui/icons/PhotoLibraryOutlined'
import IconSettingsOutlined from '@material-ui/icons/SettingsOutlined'
import IconShoppingCartOutlined from '@material-ui/icons/ShoppingCartOutlined'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
// App Imports
import params from '../../../setup/config/params'
import routes from '../../../setup/routes'
import styles from './styles'



// Component
class Sidebar extends PureComponent {
  isActiveRoute = (routePath) => {
    const { location } = this.props

    return location.pathname.indexOf(routePath) !== -1 ? 'primary' : 'inherit'
  }

  render() {
    const { classes, auth: { user } } = this.props

    return (
      <div className={classes.sidenavWrapper}>
        <div className={classes.sidenav}>
          <List component="nav">
            {/* Dashboard */}
            <ListItem
              to={routes.userDashboard.path}
              component={Link}
              button
            >
              <ListItemIcon>
                <IconDashboardOutlined color={this.isActiveRoute(routes.userDashboard.path)} />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>

            {/* Orders */}
            <ListItem
              to={routes.orderList.path(1)}
              component={Link}
              button
            >
              <ListItemIcon>
                <IconShoppingCartOutlined color={this.isActiveRoute(routes.orderList.activePath)} />
              </ListItemIcon>
              <ListItemText primary="Orders" />
            </ListItem>

            {/* Banners */}
                <ListItem
                  to={routes.bannerList.path}
                  component={Link}
                  button
                >
                  <ListItemIcon>
                    <IconPhotoLibraryOutlined color={this.isActiveRoute(routes.bannerList.path)} />
                  </ListItemIcon>
                  <ListItemText primary="Banners" />
                </ListItem>


            {/* Settings */}
                <ListItem
                  to={routes.settings.path}
                  component={Link}
                  button
                >
                  <ListItemIcon>
                    <IconSettingsOutlined color={this.isActiveRoute(routes.settings.path)} />
                  </ListItemIcon>
                  <ListItemText primary="Settings" />
                </ListItem>



            
          </List>

          <Divider />

          <List component="nav">
            {/* Categories */}
            {
              user.role === params.user.roles.admin.key &&
              <ListItem
                to={routes.categoryList.path}
                component={Link}
                button
              >
                <ListItemIcon>
                  <IconCategoryOutlined color={this.isActiveRoute(routes.categoryList.path)} />
                </ListItemIcon>
                <ListItemText primary="Categories" />
              </ListItem>
            }

            {/* Products */}
            <ListItem
              to={routes.productList.path(1)}
              component={Link}
              button
            >
              <ListItemIcon>
                <IconFilterNoneOutlined color={this.isActiveRoute(routes.productList.activePath)} />
              </ListItemIcon>
              <ListItemText primary="Products" />
            </ListItem>

            
          </List>

          <Divider />

          {
            user.role === params.user.roles.admin.key &&
            <>
              <List component="nav">
                
                {/* Customers */}
                <ListItem
                  to={routes.customerList.path(1)}
                  component={Link}
                  button
                >
                  <ListItemIcon>
                    <IconPeopleOutline color={this.isActiveRoute(routes.customerList.activePath)} />
                  </ListItemIcon>
                  <ListItemText primary="Customers" />
                </ListItem>

                {/* Delivery Person */}

                {/* <ListItem
                  to={routes.deliveryBoyList.path}
                  component={Link}
                  button
                >
                  <ListItemIcon>
                    <IconDirectionsBikeOutlined color={this.isActiveRoute(routes.deliveryBoyList.path)} />
                  </ListItemIcon>
                  <ListItemText primary="Delivery Boy" />
                </ListItem> */}

                {/* Areas */}
                {/*<ListItem
                  to={routes.pincodeList.path}
                  component={Link}
                  button
                >
                  <ListItemIcon>
                    <IconLocationCityOutlined color={this.isActiveRoute(routes.pincodeList.path)} />
                  </ListItemIcon>
                  <ListItemText primary="Areas" />
                </ListItem>
              */}
              </List>
              <Divider />

              <List component="nav">
                {/* Broadcast Message */}
                {/*<ListItem
                  to={routes.broadcastList.path}
                  component={Link}
                  button
                >
                  <ListItemIcon>
                    <IconForumOutlined color={this.isActiveRoute(routes.broadcastList.path)} />
                  </ListItemIcon>
                  <ListItemText primary="Broadcast Msg" />
                </ListItem>*/}

                

                
                
              </List>

              {/* Reports */}
            {
              user.role === params.user.roles.admin.key &&
              <ListItem
                to={routes.userReports.path}
                component={Link}
                button
              >
                <ListItemIcon>
                  <IconBarChart color={this.isActiveRoute(routes.userReports.path)} />
                </ListItemIcon>
                <ListItemText primary="Reports" />
              </ListItem>
            }

            {/* Vendors */}
                <ListItem
                  to={routes.vendorList.path(1)}
                  component={Link}
                  button
                >
                  <ListItemIcon>
                    <IconPeople color={this.isActiveRoute(routes.vendorList.activePath)} />
                  </ListItemIcon>
                  <ListItemText primary="Vendors" />
                </ListItem>

                {/* Feedback */}
                <ListItem
                  to={routes.feedbackList.path}
                  component={Link}
                  button
                >
                  <ListItemIcon>
                    <IconForumOutlined color={this.isActiveRoute(routes.feedbackList.path)} />
                  </ListItemIcon>
                  <ListItemText primary="Feedback" />
                </ListItem>

                <ListItem
                  to={routes.rewardList.path}
                  component={Link}
                  button
                >
                  <ListItemIcon>
                  <GiftIcon color={this.isActiveRoute(routes.rewardList.path)} />                  </ListItemIcon>
                  <ListItemText primary="Rewards" />
                </ListItem>

              <Divider />
            </>
          }

          <ListItem>
            {/* Copyright */}
            <Typography variant="caption" color="textSecondary">
              &copy; {params.site.year} <a href={params.site.url.website} target="_blank" rel="noopener noreferrer">{params.site.name}</a>
            </Typography>
          </ListItem>
        </div>
      </div>
    )
  }
}

// Component Properties
Sidebar.propTypes = {
  auth: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}

// Component State
function sidebarState(state) {
  return {
    auth: state.auth
  }
}

export default withRouter(connect(sidebarState, {})(withStyles(styles)(Sidebar)))
