// Component Styles
const styles = theme => ({
  root: {
    padding: theme.spacing(3)
  },

  padding: {
    padding: `${ theme.spacing(2) }px ${ theme.spacing(3) }px`
  }
})

export default styles
