// Imports
import React from 'react'
import PropTypes from 'prop-types'

// UI Imports
import Toolbar from '@material-ui/core/Toolbar/Toolbar'
import Typography from '@material-ui/core/Typography/Typography'
import { withStyles } from '@material-ui/core/styles/index'
import styles from './styles'

// App Imports
import params from '../../../setup/config/params'
import Section from '../../common/Section'

// Component       <Typography>Phone: { params.site.contact.help.phone.line1.number } or { params.site.contact.help.phone.line2.number }</Typography> 

const Contact = ({ classes }) => (
  <div>
    <Toolbar className={classes.toolbar}>
      <Typography variant="h6" color="inherit" className={classes.grow}>
        Contact
      </Typography>
    </Toolbar>

    <Section>
      <Typography paragraph>Contact us for any queries or suggestions.</Typography>
      <Typography>Email: { params.site.contact.default.email }</Typography>
    </Section>
  </div>
)

// Component Properties
Contact.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Contact)
