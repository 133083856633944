// Actions Types

// Auth
export const LOGIN_REQUEST = 'AUTH/LOGIN_REQUEST'
export const LOGIN_RESPONSE = 'AUTH/LOGIN_RESPONSE'
export const SET_USER = 'AUTH/SET_USER'
export const LOGOUT = 'AUTH/LOGOUT'

// Dashboard counts
export const GET_DASHBOARD_COUNT_REQUEST = 'USER/GET_DASHBOARD_COUNT/REQUEST'
export const GET_DASHBOARD_COUNT_RESPONSE = 'USER/GET_DASHBOARD_COUNT/RESPONSE'
export const GET_DASHBOARD_COUNT_DONE = 'USER/GET_DASHBOARD_COUNT/DONE'
export const GET_DASHBOARD_COUNT_RESET = 'USER/GET_DASHBOARD_COUNT/RESET'
export const GET_DASHBOARD_COUNT_FILTER = 'USER/GET_DASHBOARD_COUNT/FILTER'
