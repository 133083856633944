// Imports
import axios from 'axios'
// App Imports
import { API_URL } from '../../../../setup/config/env'


// Actions

// Create or update
export function createOrUpdate({ deliveryBoy }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'deliveryBoyCreateOrUpdate',
      params: deliveryBoy
    })
  }
}

// Feature toggle
export function featureToggle({ deliveryBoyId, isFeatured }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'deliveryBoyFeatureToggle',
      params: { deliveryBoyId, isFeatured }
    })
  }
}

// Publish toggle
export function publishToggle({ deliveryBoyId, isPublished }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'deliveryBoyPublishToggle',
      params: { deliveryBoyId, isPublished }
    })
  }
}

// Delete
export function remove({ deliveryBoyId }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'deliveryBoyDelete',
      params: { deliveryBoyId  }
    })
  }
}
