// App Imports
import pincode from '../modules/pincode/api/routes'
import banner from '../modules/banner/api/routes'
import broadcastmsg from '../modules/broadcastmsg/api/routes'
import feedback from '../modules/feedback/api/routes'
import reward from '../modules/rewards/api/routes'
import category from '../modules/category/api/routes'
import customer from '../modules/customer/api/routes'
import delivery from '../modules/delivery/api/routes'
import log from '../modules/log/api/routes'
import order from '../modules/order/api/routes'
import pages from '../modules/pages/api/routes'
import product from '../modules/product/api/routes'
import setting from '../modules/setting/api/routes'
import user from '../modules/user/api/routes'
import vendor from '../modules/vendor/api/routes'

// Combined routes
export default {
  ...pages,
  ...user,
  ...customer,
  ...category,
  ...product,
  ...pincode,
  ...vendor,
  ...order,
  ...banner,
  ...setting,
  ...delivery,
  ...broadcastmsg,
  ...feedback,
  ...log,
  ...reward
}
