// Imports
import axios from 'axios'

// App Imports
import { API_URL } from '../../../../setup/config/env'


// Actions

export function update({ vendor }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'customerProfileUpdate',
      params: vendor
    })
  }
}