// UI Imports
import { createTheme } from '@mui/material/styles';
import { blue, blueGrey } from '@mui/material/colors';

export default createTheme({
  palette: {
    primary: blue,
    secondary: {
      main: blueGrey[400]
    },
  }
});