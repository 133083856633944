// Imports
import axios from 'axios'

// App Imports
import {
  API_URL
} from '../../../../setup/config/env'

// Actions

// Deliver toggle
export function deliverToggle({
  orderId,
  isDelivered
}) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'orderDeliverToggle',
      params: {
        orderId,
        isDelivered
      }
    })
  }
}

// orderaccepted toggle
export function orderacceptedToggle({
  orderId,
  isOrderAccepted
}) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'orderacceptedToggle',
      params: {
        orderId,
        isOrderAccepted
      }
    })
  }
}

// Paid toggle
export function paidToggle({
  orderId,
  isPaid
}) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'orderPaidToggle',
      params: {
        orderId,
        isPaid
      }
    })
  }
}

// Delete
export function remove({
  orderId
}) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'orderDelete',
      params: {
        orderId
      }
    })
  }
}

// Cancel
export function cancel({
  orderId
}) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'orderCancel',
      params: {
        orderId
      }
    })
  }
}

export function updateOrderDetails({
  orderId,
  orderItems,
  editId,
  detail
}) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'orderItemUpdate',
      params: {
        orderId,
        orderItems,
        editId,
        detail
      }
    })
  }
}

export function updateOrderDetailsSubscription({
  orderId,
  orderItems,
  detail,
  orderItemDelivery
}) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'orderItemUpdateSubscription',
      params: {
        orderId,
        orderItems,
        detail,
        orderItemDelivery
      }
    })
  }
}

export function orderCalculation({
  cart
}) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'orderCalculation',
      params: {
        cart
      }
    })
  }
}

export function orderCalculationAdmin({
  orderId,
  totQuantity
}) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'orderCalculationAdmin',
      params: {
        orderId,
        totQuantity
      }
    })
  }
}