// Actions Types

// List
export const LIST_REQUEST = 'DELIVERYBOY/LIST/REQUEST'
export const LIST_RESPONSE = 'DELIVERYBOY/LIST/RESPONSE'
export const LIST_DONE = 'DELIVERYBOY/LIST/DONE'
export const LIST_RESET = 'DELIVERYBOY/LIST/RESET'

// List parent only
export const LIST_PARENT_REQUEST = 'DELIVERYBOY/LIST_PARENT/REQUEST'
export const LIST_PARENT_RESPONSE = 'DELIVERYBOY/LIST_PARENT/RESPONSE'
export const LIST_PARENT_DONE = 'DELIVERYBOY/LIST_PARENT/DONE'
export const LIST_PARENT_RESET = 'DELIVERYBOY/LIST_PARENT/RESET'

// List subdeliveryBoy by parent
export const LIST_BY_PARENT_REQUEST = 'DELIVERYBOY/LIST_BY_PARENT/REQUEST'
export const LIST_BY_PARENT_RESPONSE = 'DELIVERYBOY/LIST_BY_PARENT/RESPONSE'
export const LIST_BY_PARENT_DONE = 'DELIVERYBOY/LIST_BY_PARENT/DONE'
export const LIST_BY_PARENT_RESET = 'DELIVERYBOY/LIST_BY_PARENT/RESET'
