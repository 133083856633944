// Imports
import React from 'react'
import PropTypes from 'prop-types'

// UI Imports
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles/index'
import styles from './styles'

// Component
const SectionPaper = ({ padding, classes, ...props}) => (
  <div className={classes.root} { ...props }>
    <Paper elevation={1} className={ padding ? classes.padding : '' }>
      { props.children }
    </Paper>
  </div>
)

// Component Properties
SectionPaper.propTypes = {
  classes: PropTypes.object.isRequired,
  padding: PropTypes.bool
}
SectionPaper.defaultProps = {
  padding: false
}

export default withStyles(styles)(SectionPaper)
