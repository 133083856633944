// Configurations

// URL
export const STORE_URL = process.env.REACT_STORE_URL
export const ADMIN_URL = process.env.REACT_APP_ADMIN_URL
export const API_URL = process.env.REACT_APP_API_URL
export const API_WEBSOCKET_URL = process.env.REACT_APP_API_WEBSOCKET_URL
export const IMAGE_SOURCE_PATH = process.env.REACT_APP_IMAGE_SOURCE_PATH

export const secret_key = process.env.SECRET_KEY
export const secret_iv = process.env.SECRET_IV
export const ecnryption_method = process.env.ECNRYPTION_METHOD

// Encryption Secret key
export const encrption_secrey_key = process.env.REACT_APP_ENCRYPTION_SECRETKEY;