// Imports
import { Grid, withStyles } from '@material-ui/core'
import Button from '@mui/material/Button'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import React, { PureComponent , useState }  from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { messageShow } from '../../common/api/actions'
import SectionPaper from '../../common/SectionPaper'
import Image from '@material-ui/icons/Image'; 
import Checkbox from '@mui/material/Checkbox';
import { remove } from '../api/actions/mutation'
import routes from '../api/routes'
import styles from './styles'
import 'react-datepicker/dist/react-datepicker.css';
import InputBase from '@material-ui/core/InputBase'

// Component
class Rewards extends PureComponent {
  
  render() {
    return (
        <div>
          <Grid style={{ paddingLeft: '1030px',paddingTop:"20px" }}>
          <Link to={routes.createrewards.path}>
                      <Button
                      aria-label={"Save"}
                      size="medium"
                      color={"primary"}
                      variant="contained"
                      >
                        CREATE
                      </Button> </Link>
                        </Grid>
        <SectionPaper>
        <TableHead>
        <TableRow>
        <TableCell style={{  width: 180 }}>Image</TableCell>
        <TableCell style={{  width: 240 }}>Earned <br/>Points value</TableCell>
        <TableCell style={{  width: 300 }}>Rewards Name</TableCell> 
        <TableCell style={{  width: 100 }}>Offline Deals</TableCell>
        <TableCell style={{  width: 100 }}>Min Order Amount  </TableCell> 
        <TableCell style={{  width: 150 }}>Voucher Code </TableCell> 
        <TableCell style={{  width: 100 }}>Issue Volume </TableCell> 
        <TableCell style={{  width: 100 }}>Expiry Date</TableCell> 
        <TableCell style={{  width: 100 }} align="center">
            Actions </TableCell> 
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
        <TableCell style={{  width: 180 }}>
        <Image/>
        </TableCell>
        <TableCell >
        <InputBase
        style={{  width: 60 }}
        placeholder={'Points'}
        type='number'
        inputProps={{
        pattern: '[0-9]*', 
        inputMode: 'numeric', 
        min: '0', 
        }}
        /> 
        <InputBase
         style={{  width: 60 }}
        placeholder={'Values'}
        type='number'
        inputProps={{
        pattern: '[0-9]*', 
        inputMode: 'numeric', 
        min: '0', 
        }}
        /> 
        </TableCell> 
        <TableCell style={{  width: 300 }}>
          <InputBase placeholder={'10% Discount voucher'}></InputBase>
        </TableCell>

        <TableCell >
        <Checkbox
                      color="primary"
                    />
        </TableCell> 

        <TableCell style={{  width: 100 }}>
        <InputBase
        placeholder={'Count'}
        type='number'
        inputProps={{
        pattern: '[0-9]*', 
        inputMode: 'numeric', 
        min: '0', 
        }}
        />

        </TableCell>
        <TableCell style={{  width: 100 }}>
        <InputBase
        placeholder={'Points'}
        type='number'
        inputProps={{
        pattern: '[0-9]*', 
        inputMode: 'numeric', 
        min: '0', 
        }}
        /> 
        </TableCell> 
        <TableCell style={{  width: 100 }}>
        <InputBase
        placeholder={'Volume'}
        type='number'
        inputProps={{
        pattern: '[0-9]*', 
        inputMode: 'numeric', 
        min: '0', 
        }}
        /> 
        </TableCell>

        <TableCell style={{  width: 150 }}>
        <InputBase placeholder={'DD/MM/YYYY'}></InputBase>  

        </TableCell> 
                  <div style={{  width: 140 }}>
                  <Button style={{  width: 40 }}>
                  <EditIcon/>
                  </Button>
                  <Button  style={{  width: 40 }}>
                  <DeleteIcon />  
                  </Button>
                  </div> 
        </TableRow>


      </TableBody>
     </SectionPaper>
     </div>
    )
  }
}
export default connect(null, {  messageShow,  })(withStyles(styles)(Rewards))
