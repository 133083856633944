// Imports
// UI Imports
import Grid from '@material-ui/core/Grid/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import CheckIcon from '@mui/icons-material/Check';
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
// App Imports
import { nullToEmptyString } from '../../../setup/helpers'
import { messageShow } from '../../common/api/actions'
import Loading from '../../common/Loading'
import SectionPaper from '../../common/SectionPaper'
import { save } from '../api/actions/mutation'
import routes from '../api/routes'
import styles from './styles'

const CHECK_BOX = "checkbox";

// Component
class CreateOrUpdate extends Component {

  constructor(props) {
    super(props)

    this.broadcasts = {
      msgTitle: '',
      description: '',
      smsNotificatonFlag: false,
      pushNotificatonFlag: false
    }

    this.state = {
      isLoading: false,
      isSubmitting: false,
      broadcasts: this.broadcasts
    }
  }

  componentDidMount() {
    this.refresh()
  }

  refresh = async () => {
    const { messageShow } = this.props

    this.isLoadingToggle(true)

    try {
      /* const { data } = await detail()

      if(data.success && data.data) {
        this.setState({
          broadcasts: data.data
        })
      } */
    } catch (error) {
      messageShow('Some error occurred. Please try again.')
    } finally {
      this.isLoadingToggle(false)
    }
  }

  onSave = async event => {
    event.preventDefault()

    const { save, messageShow, history } = this.props

    const { broadcasts } = this.state

    this.isSubmittingToggle(true)

    try {
      const { data } = await save(broadcasts)

      this.isSubmittingToggle(false)
      history.push(routes.broadcastList.path)
      messageShow(data.message)
    } catch (error) {
      this.isSubmittingToggle(false)

      messageShow('Some error occurred. Please try again.')
    }
  }

  isLoadingToggle = isLoading => {
    this.setState({
      isLoading
    })
  }

  isSubmittingToggle = isSubmitting => {
    this.setState({
      isSubmitting
    })
  }

  onChange = (e) => {
    let { name, value, type, checked } = e.target;
    const { broadcasts } = this.state

    broadcasts[name] = type === CHECK_BOX ? !!checked : value

    this.setState({
      broadcasts
    })
  }

  render() {
    const { classes } = this.props
    const { isLoading, isSubmitting, broadcasts } = this.state
    let { smsNotificatonFlag, pushNotificatonFlag, msgTitle, description } = broadcasts;

    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            Broadcast Message
          </Typography>
        </Toolbar>

        <Grid item xs={12} lg={6}>
          <SectionPaper padding>
            {
              isLoading
                ? <Loading />
                : <form onSubmit={this.onSave}>
                  {/* Input - Message Title */}
                  <Grid item xs={12}>
                    <TextField
                      name={'msgTitle'}
                      value={nullToEmptyString(msgTitle)}
                      onChange={this.onChange}
                      label={'Title'}
                      placeholder={'Enter Title'}
                      required={true}
                      margin={'dense'}
                      autoComplete={'off'}
                      fullWidth
                      autoFocus
                    />
                  </Grid>

                  {/* Input - Message Description */}
                  <Grid item xs={12}>
                    <TextField
                      name={'description'}
                      value={nullToEmptyString(description)}
                      onChange={this.onChange}
                      label={'Description'}
                      placeholder={'Enter description'}
                      required={true}
                      margin={'dense'}
                      autoComplete={'off'}
                      multiline
                      rowsMax={3}
                      rows={3}
                      fullWidth
                    />
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={!!nullToEmptyString(smsNotificatonFlag)}
                            onChange={this.onChange}
                            color='primary'
                            name={"smsNotificatonFlag"}
                            required={!(smsNotificatonFlag || pushNotificatonFlag)}
                          />
                        }
                        label='SMS notification'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={!!nullToEmptyString(pushNotificatonFlag)}
                            onChange={this.onChange}
                            color='primary'
                            name={"pushNotificatonFlag"}
                            required={!(smsNotificatonFlag || pushNotificatonFlag)}
                          />
                        }
                        label='PUSH notification'
                      />
                    </Grid>
                  </Grid>

                  {/* Button - Save */}
                  <Grid item xs={12} className={classes.buttonsContainer}>
                    <IconButton
                      type={'submit'}
                      aria-label={'Save'}
                      color={'primary'}
                      disabled={isSubmitting}
                    >
                      <CheckIcon/>
                    </IconButton>
                  </Grid>
                </form>
            }
          </SectionPaper>
        </Grid>
      </div>
    )
  }
}

// Component Properties
CreateOrUpdate.propTypes = {
  broadcasts: PropTypes.object.isRequired,
  save: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
}

// Component State
function createOrEditState(state) {
  return {
    broadcasts: state.broadcasts
  }
}

export default connect(createOrEditState, { save, messageShow })(withStyles(styles)(CreateOrUpdate))
