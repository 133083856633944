// Component Styles
const styles = theme => ({
  grow: {
    flexGrow: 1
  },

  toolbar: {
    borderBottom: `1px solid rgba(0, 0, 0, 0.12)`
  },

  search: {
    marginTop: 5,
    marginLeft: 10,
    marginRight: 10,
    minWidth: 200
  },
})

export default styles
