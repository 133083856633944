// Imports
import axios from 'axios'

// App Imports
import { API_URL } from '../../../../setup/config/env'

// Get list order
//export function list({ page, vendorId }) {
export function list({ page, filter, listAll }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'orderSearch',
      //params: { page, vendorId }
      params: { page, filter, listAll}
    })
  }
}

// Get detail
export function detail({ orderId }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'orderDetail',
      params: { orderId }
    })
  }
}

// Get count
export function getCount() {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'orderCount'
    })
  }
}
