// Imports
import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// App Imports
import params from '../../setup/config/params'
import routes from '../../setup/routes'

// Component
const AuthCheck = ({ auth: { isAuthenticated, user } }) => (
  isAuthenticated && ([params.user.roles.admin.key, params.user.roles.vendor.key].indexOf(user.role) !== -1)
    ? <Redirect to={routes.userDashboard.path} />
    : ''
)

// Component Properties
AuthCheck.propTypes = {
  auth: PropTypes.object.isRequired
}

// Component State
function authCheckState(state) {
  return {
    auth: state.auth
  }
}

export default connect(authCheckState, {})(AuthCheck)
