// Imports
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// UI Imports
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

// App Imports
import { logout } from "../../user/api/actions/query";
import { messageShow } from "../api/actions";
import Header from "../Header";
import Message from "../Message";
import Sidebar from "../Sidebar";

import notification from "../../../assets/audio/notification.wav";

// Component
class Layout extends PureComponent {
  render() {
    const {
      classes,
      auth: { isAuthenticated },
      children,
    } = this.props;

    return (
      <div>
        {/* Header */}
        <Header />

        <div hidden>
          <audio id="sound" src={notification}></audio>
          <button type="button" id="notify" onClick={() => document.getElementById('sound')?.play()}></button>
        </div>

        {/* Body */}
        <main className={classes.main}>
          {/* Side navigation */}
          {isAuthenticated && <Sidebar />}

          {/* Content */}
          <div className={classes.contentWrapper}>
            <div className={classes.content}>{children}</div>
          </div>
        </main>

        {/* Message */}
        <Message />
      </div>
    );
  }
}

// Component Properties
Layout.propTypes = {
  auth: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

// Component State
function layoutState(state) {
  return {
    auth: state.auth,
  };
}

export default withRouter(
  connect(layoutState, { logout, messageShow })(withStyles(styles)(Layout))
);
