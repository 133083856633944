// Actions Types

// List
export const LIST_REQUEST = 'CATEGORY/LIST/REQUEST'
export const LIST_RESPONSE = 'CATEGORY/LIST/RESPONSE'
export const LIST_DONE = 'CATEGORY/LIST/DONE'
export const LIST_RESET = 'CATEGORY/LIST/RESET'

// List parent only
export const LIST_PARENT_REQUEST = 'CATEGORY/LIST_PARENT/REQUEST'
export const LIST_PARENT_RESPONSE = 'CATEGORY/LIST_PARENT/RESPONSE'
export const LIST_PARENT_DONE = 'CATEGORY/LIST_PARENT/DONE'
export const LIST_PARENT_RESET = 'CATEGORY/LIST_PARENT/RESET'

// List subcategory by parent
export const LIST_BY_PARENT_REQUEST = 'CATEGORY/LIST_BY_PARENT/REQUEST'
export const LIST_BY_PARENT_RESPONSE = 'CATEGORY/LIST_BY_PARENT/RESPONSE'
export const LIST_BY_PARENT_DONE = 'CATEGORY/LIST_BY_PARENT/DONE'
export const LIST_BY_PARENT_RESET = 'CATEGORY/LIST_BY_PARENT/RESET'
