// Imports
import { Grid, withStyles } from '@material-ui/core'
import Button from '@mui/material/Button'
import Table from "@material-ui/core/Table";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import NotificationsIcon from '@material-ui/icons/Notifications';
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import React, { PureComponent , useState }  from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { messageShow } from '../../common/api/actions'
import SectionPaper from '../../common/SectionPaper'
import PropTypes from "prop-types";
import Loading from "../../common/Loading";
import EmptyMessage from "../../common/EmptyMessage";
import { remove } from '../api/actions/mutation'
import routes from '../api/routes'
import styles from './styles'
import dateFormat from 'date-fns/format'
import dateParseISO from 'date-fns/parseISO'
import 'react-datepicker/dist/react-datepicker.css';
import {listGoals} from "../api/actions/query";



// Component
class Goals extends PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      goals: [],
      count: 0
    }
  }

  componentDidMount() {
    this.refresh();
  }

  refresh = async () => {
    const { listGoals,isLoading } = this.props;
    this.isLoadingToggle(isLoading)
    try {
      const { data } = await listGoals()
      if (data.success) {
        this.setState({
          goals: data.data,
        })
      } else {
        messageShow(data.message)
      }
    } catch (error) {
      messageShow('There was some error. Please try again.')
    } finally {
      this.isLoadingToggle(false)
    }
  }

  onDelete = async (goalId) => {
    let check = window.confirm(
      "Are you sure you want to delete this category?"
    );

    if (check) {
      const { remove, messageShow } = this.props;

      try {
        const { data } = await remove({ goalId });

        messageShow(data.message);

        if (data.success) {
          this.refresh();
        }
      } catch (error) {
        messageShow("Some error occurred. Please try again.");
      }
    }
  }

  isLoadingToggle = isLoading => {
    this.setState({
      isLoading
    })
  }

  render() {
    const {goals , isLoading } = this.state;
    const list = goals
    return (
      <div>
                    <Grid style={{ paddingLeft: '980px',paddingTop:"20px" }}>
                       <Link to={routes.creategoals.path}>
                      <Button
                          aria-label={"Save"}
                          size="medium"
                          color={"primary"}
                          variant="contained"
                      >
                          CREATE
                      </Button>
                        </Link>
                        </Grid>

                    <SectionPaper>
                  <Table padding={"normal"}>
                  <TableHead>
                  <TableRow>
                  <TableCell width={150}>Goal name</TableCell>
                  <TableCell width={180}>Description</TableCell>
                  <TableCell width={150}>Event name</TableCell> 
                  <TableCell width={150}>Multiplier event</TableCell>
                  <TableCell width={180}>Reward points </TableCell> 
                  <TableCell width={140}>Start date </TableCell> 
                  <TableCell width={140}>Expiry date </TableCell> 
                  <TableCell width={180} align="center">
                  
                      Actions </TableCell> 
                  </TableRow>
                </TableHead>
                
                {isLoading ? (
                <Loading />
                ) : list.length === 0 ? (
                <EmptyMessage message={"You have not added any goals yet."} />
                ) : (
                <TableBody>
                
                {list.map(({_id,goalName,description,eventName,multiplierEvent,rewardPoints,startDate,expiryDate }) => (

                <TableRow key={_id}>
                <TableCell>{goalName}</TableCell>
                <TableCell>{description}</TableCell>
                <TableCell>{eventName}</TableCell>
                <TableCell>{multiplierEvent}</TableCell>
                <TableCell>{rewardPoints}</TableCell>
                <TableCell>{ dateFormat(dateParseISO(startDate), 'yyyy-MM-dd') }</TableCell>
                <TableCell>{ dateFormat(dateParseISO(expiryDate), 'yyyy-MM-dd') }</TableCell>
                <TableCell>
                  <div style={{  width: 200 }}>
                  <Button href={`/rewards/edit/${_id}`}><EditIcon/></Button>
                  <Button  color="error" onClick={() => this.onDelete(_id)}>
                  <DeleteIcon />  
                  </Button>
                  <Button style={{  width: 30 }}>
                  <NotificationsIcon/>
                  </Button>
                  </div> 
                  </TableCell>
                  </TableRow>
                ))}

                </TableBody>
                )} 

                </Table>
              
      </SectionPaper>
      </div>
    )
  }
}
Goals.propTypes = {
  listGoals:PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};
function listState(state) {
  return {
    goalsParent: state.goalsParent,
  };
}

export default connect( listState,{
  listGoals,
  remove,
  messageShow
  })(withStyles(styles)(Goals))
