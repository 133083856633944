// Imports
import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import isArray from 'lodash/isArray'

// App Imports
import routes from '../user/api/routes'

// Component
const RoutePrivate = (props) => {
  return(
    props.auth.isAuthenticated
      ? props.role
      ? (isArray(props.role) ? props.role.indexOf(props.auth.user.role) !== -1 : props.auth.user.role === props.role)
        ? <Route {...props} component={props.component}/>
        : <Redirect to={routes.userLogin.path}/>
      : <Route {...props} component={props.component}/>
      : <Redirect to={routes.userLogin.path}/>
  )
}

// Component Properties
RoutePrivate.propTypes = {
  auth: PropTypes.object.isRequired,
}

// Component State
function routePrivateState(state) {
  return {
    auth: state.auth
  }
}

export default connect(routePrivateState, {})(RoutePrivate);
