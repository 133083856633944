// App Imports
import { API_URL } from './config/env'
import params from './config/params'

// Helpers

// Render element or component by provided condition

export function renderIf(condition, renderFn) {
  return condition ? renderFn() : null
}

// Substring with ...
export function subString(string = '', length = 0) {
  return string.length > length ? `${ string.substr(0, length) }...` : string
}

// Return empty string if value is null
export function nullToEmptyString(value) {
  return value || ''
}

// Return zero if value is null
export function nullToZero(value) {
  return value === null ? 0 : value
}

// Add (s) to any string by count
export function plural(value) {
  return value === 1 ? '' : 's'
}

// Slug
export function slug(text) {
  return text.toLowerCase().replace(/[^\w ]+/g,'').replace(/ +/g,'-')
}

// Capitalize first letter of string
export function capitalizeFirstLetter(string) {
  return string.replace(/^./, str => str.toUpperCase())
}

// Price format
export function priceFormat(num) {
  num = Math.abs(typeof num === 'number' ? (num % 1 === 0) ? num : num.toFixed(2) : num)
  let n1, n2
  num = num + '' || ''
  n1 = num.split('.')
  n2 = n1[1] || null;
  n1 = n1[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,")
  num = n2 ? n1 + '.' + n2 : n1
  return num
}

// Image Kyc
export function imageKyc(image = 'default.jpg') {
  return `${ API_URL }/${ params.kyc.image.path }/${ image }`
}

