// UI Imports
import grey from '@material-ui/core/colors/grey'

// Component Styles
const styles = theme => ({
  root: {
    padding: theme.spacing(),
    backgroundColor: grey[500]
  },

  grow: {
    flexGrow: 1
  },

  toolbar: {
    borderBottom: `1px solid rgba(0, 0, 0, 0.12)`
  }
})

export default styles
