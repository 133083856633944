// Imports
import axios from 'axios'

// App Imports
import { API_URL } from '../../../../setup/config/env'

// Signup vendor
export function signup({ name, email, mobile, password }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'userSignupVendor',
      params: { name, email, mobile, password }
    })
  }
}

// Publish toggle vendor
export function publishToggleVendor({ vendorId, isPublished }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'userPublishToggleVendor',
      params: { vendorId, isPublished }
    })
  }
}

// Remove vendor
export function removeVendor(data) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'userDeleteVendor',
      params: data
    })
  }
}

export function updateDetail({ user }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'userProfileUpdate',
      params: user
    }).then(user => {
      let data = JSON.parse(user.config.data)
      window.localStorage.setItem('user', JSON.stringify(data.params))
      return user
    })
  }
}

// Send Email
export function sendMail(data) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'sendResetPasswordEmail',
      params: data
    })
  }
}

export function updatePassword({ token, password}) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'updatePassword',
      params: { token, password }
    })
  }
}