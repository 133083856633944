// Imports
import axios from 'axios'

// App Imports
import { API_URL } from '../../../../setup/config/env'

// Actions

// Create or update
export function createOrUpdate(banner) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'bannerCreateOrUpdate',
      params: banner
    })
  }
}

// Publish toggle
export function publishToggle({ bannerId, isPublished }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'bannerPublishToggle',
      params: { bannerId, isPublished }
    })
  }
}

// Delete
export function remove({ bannerId }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'bannerDelete',
      params: { bannerId  }
    })
  }
}
