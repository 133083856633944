// App Imports
import params from '../../../setup/config/params'
import List from '../List'

// Pages routes
export default {
  logList: {
    path: (page = ':page') => `/logs/${ page }`,
    activePath: '/log',
    component: List,
    auth: true,
    role: params.user.roles.admin.key
  },
}
