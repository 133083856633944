// App Imports
import { messageShow } from '../modules/common/api/actions';
import { API_WEBSOCKET_URL } from './config/env';

const AUTO_RECONNECT_INTERVAL = 1000; //1 seconds
const ORDER_CREATED = 'order.created';
let store = null;
let WSConnection = null;

export const connectToWebSocket = reduxStore => {
	store = reduxStore;
	connect();
};

const connect = () => {
	const wsUrl =
		API_WEBSOCKET_URL && API_WEBSOCKET_URL.length > 0
			? API_WEBSOCKET_URL
			: getWebSocketUrlFromCurrentLocation();
	const token = window.localStorage.getItem('token')
	WSConnection = new WebSocket(`${API_WEBSOCKET_URL}/ws/dashboard?token=${token}`);
	if (!token)
		return WSConnection.close();

	WSConnection.onmessage = onMessage;
	WSConnection.onopen = onOpen;
	WSConnection.onclose = onClose;
	WSConnection.onerror = onError;
};

const getWebSocketUrlFromCurrentLocation = () => {
	const wsProtocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
	return `${wsProtocol}//${window.location.host}`;
};

const onMessage = event => {
	try {
		const message = JSON.parse(event.data);
		eventHandler(message);
	} catch (err) { }
};

const onOpen = () => {
	console.log('Connection established.');
};

const onError = () => { };

const onClose = event => {
	if (event.code !== 1000) {
		console.log(`WebSocket connection closed with code: ${event.code}.`);
		// try to reconnect
		setTimeout(() => {
			connect();
		}, AUTO_RECONNECT_INTERVAL);
	}
};

const onCloseEvent = () => {
	const token = window.localStorage.getItem('token')
	if (!token) {
		WSConnection?.close();
	}
}

const showNotification = (title, body, requireInteraction = false) => {
	let msg = new Notification(title, {
		body: body,
		tag: 'dashboard',
		requireInteraction: requireInteraction
	});

	/* msg.addEventListener('click', event => {
		parent.focus();
		event.target.close();
	}); */
};

const eventHandler = ({ event, payload }) => {
	switch (event) {
		case ORDER_CREATED:
			const order = payload;
			store.dispatch(messageShow({text: 'You have New Orders...!', duration: null}))
			document.getElementById('notify')?.click();
			document.getElementById('refreshOrder')?.click();
			// document.getElementById('sound').setAttribute('loop', true);
			//store.dispatch(fetchOrders());
			// play with a callback
			showNotification(
				`${order.number}`,
				`${order.shipping_address.full_name}, ${order.shipping_address.city}`,
				true
			);
			break;
		default:
			break;
	}

};
