// Imports
import { withStyles } from '@material-ui/core'
import Button from '@mui/material/Button'
import FileUpload from "@mui/icons-material/FileUpload";
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import React, { PureComponent , useState }  from 'react'
import { connect } from 'react-redux'
import { messageShow } from '../../common/api/actions'
import SectionPaper from '../../common/SectionPaper'
import IconArrowBack from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";
import { remove } from '../api/actions/mutation'
import routes from '../api/routes'
import styles from './styles'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from "@mui/material/TextField";
import Grid from "@material-ui/core/Grid/Grid";

// Component
class Createrewards extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: null,
      goalName: "",
      description: "",
      eventName: "",
      multiplierEvent: "",
      rewardsPoints: "",
      expiryDate: null,
    };
  }
  onDateChange = (date) => {
    this.setState({
      selectedDate: date,
    });
  };
  onType = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, this.checkRequiredInputs);
  };
  back = () => {
    const { history } = this.props;

    if (history.length > 2) {
      history.goBack();
    } else {
      history.push(routes.goals.path(1));
    }
  };

  render() {
    const {classes} = this.props;
    return (
      
      <div>
        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={this.back}
          >
            <IconArrowBack />
          </IconButton>

          <Typography variant="h6" color="inherit" >
             Create Rewards
          </Typography>
        </Toolbar>
          <Toolbar className={classes.toolbar}>
           <Grid item xs={12} lg={6}>
           <SectionPaper padding>
      <form onSubmit={this.onCreateOrUpdate}>
        <Grid item xs={12} style={{ marginBottom: 24 }}>
          <TextField
            name={"Earned Points"}
            variant="standard"
            
            onChange={this.onType}
            label={"Earned Points"}
            placeholder={"Earned points"}
            required={true}
            margin={"dense"}
            autoComplete={"off"}
            fullWidth
            autoFocus
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 24 }}>
          <TextField
            name={"Earned values"}
            variant="standard"
            
            onChange={this.onType}
            label={"Earned values"}
            placeholder={"Earned values"}
            required={true}
            margin={"dense"}
            autoComplete={"off"}
            fullWidth
            autoFocus
          />
        </Grid>
          
                <Grid item xs={12} style={{ marginBottom: 24 }}>
                  <TextField
                    name={"Rewards name"}
                    variant="standard"
                    onChange={this.onType}
                    label={"Rewards name"}
                    placeholder={"Rewards name"}
                    required={true}
                    margin={"dense"}
                    autoComplete={"off"}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 24 }}>
                  <TextField
                    name={"Offline Deals"}
                    variant="standard"
                    onChange={this.onType}
                    label={"Offline Deals"}
                    placeholder={"Offline Deals"}
                    required={true}
                    margin={"dense"}
                    autoComplete={"off"}
                    fullWidth
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 24 }}>
                  <TextField
                    name={"Online Deals"}
                    variant="standard"
                    onChange={this.onType}
                    label={"Online Deals"}
                    placeholder={"Online Deals"}
                    required={true}
                    margin={"dense"}
                    autoComplete={"off"}
                    fullWidth
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 24 }}>
                  <TextField
                    name={"Minimum order amount"}
                    variant="standard"
                    onChange={this.onType}
                    label={"Minimum order amount"}
                    placeholder={"Minimum order amount"}
                    required={true}
                    margin={"dense"}
                    autoComplete={"off"}
                    fullWidth
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 24 }}>
                  <TextField
                    name={"Voucher code"}
                    variant="standard"
                    onChange={this.onType}
                    label={"Voucher code"}
                    placeholder={"Voucher code"}
                    required={true}
                    margin={"dense"}
                    autoComplete={"off"}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 24 }}>
                  <TextField
                    name={"Issue volume"}
                    variant="standard"
                    onChange={this.onType}
                    label={"Issue volume"}
                    placeholder={"Issue volume"}
                    required={true}
                    margin={"dense"}
                    autoComplete={"off"}
                    fullWidth
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  />
                </Grid>
                <Grid item xs={6} style={{ marginBottom: 8 }}>
                  <DatePicker
                    required={true}
                    onChange={this.onType}
                    label="Expiry Date"
                    
                    //value={dayjs(endDate)}
                    // slotProps={{ textField: { variant: "standard" } }}
                    // minDate={dayjs(startDate)}
                    // onChange={this.handleDateChange("endDate")}
                  />
                </Grid>
                <Grid
                  container
                  spacing={8}
                  className={classes.buttonUpload}
                  alignItems="center"
                >
                  <Grid item md={6}>
                   
                  </Grid>
                  <Grid item md={6}>
                    <input
                      accept={"image/png,image/jpeg"}
                      style={{ display: "none" }}
                      id={"contained-button-file"}
                      type={"file"}
                      onChange={this.onUpload}
                    />

                    <label htmlFor={"contained-button-file"}>
                      <Button
                        variant={"outlined"}
                        component={"span"}
                        type={"file"}
                        fullWidth
                      >
                        <FileUpload
                          fontSize="16"
                          style={{ marginInlineEnd: 16 }}
                        />
                        Upload Image
                      </Button>
                    </label>
                  </Grid>
                </Grid>
        <Grid item xs={12} className={classes.buttonsContainer}>
            <Button
              type={"button"}
              aria-label={"Close"}
              variant="outlined"
              color="secondary"
            >
              Cancel
            </Button>
          

          <Button
            style={{ marginInlineStart: 12 }}
            type={"submit"}
            aria-label={"Save"}
            color={"primary"}
            variant="contained"
          >
            Save
          </Button>
        </Grid>
      </form>
    
      </SectionPaper>
      </Grid>
      </Toolbar>
      
      </div>
    )
  }
}

export default connect(null, {  messageShow,  })(withStyles(styles)(Createrewards))

