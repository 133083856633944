// App Imports
import params from '../../../setup/config/params'
import Save from '../Save'
import List from '../List'

// Pages routes
export default {
  broadcastList: {
    path: '/broadcasts',
    component: List,
    exact: true,
    auth: true,
    role: params.user.roles.admin.key
  },
  createBroadcast: {
    path: '/broadcasts/create',
    component: Save,
    exact: true,
    auth: true,
    role: params.user.roles.admin.key
  },
}
