// App Imports
import params from '../../../setup/config/params'
import List from '../List'
import Save from '../Save'

// Pages routes
export default {
  feedbackList: {
    path: '/feedbacks',
    component: List,
    exact: true,
    auth: true,
    role: params.user.roles.admin.key
  },
  createFeedback: {
    path: '/feedbacks/create',
    component: Save,
    exact: true,
    auth: true,
    role: params.user.roles.admin.key
  },
  feedbackEdit: {
    path: (feedbackId = ':feedbackId') => (`/feedback/edit/${ feedbackId }`),
    component: Save,
    exact: true,
    auth: true,
    role: params.user.roles.admin.key
  }
}
