// Imports
import axios from 'axios'

// App Imports
import { API_URL } from '../../../../setup/config/env'

// Actions


// Get detail
export function detail({ userId }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'userDetail',
      params: { userId }
    })
  }
}