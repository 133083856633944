// Component Styles
const styles = theme => ({
  grow: {
    flexGrow: 1,
    marginLeft: 5
  },

  toolbar: {
    borderBottom: `1px solid rgba(0, 0, 0, 0.12)`
  },

  search: {
    marginTop: 5,
    marginLeft: 5,
    minWidth: 180,
    // border:"1px solid black"
  },
})

export default styles