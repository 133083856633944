// Imports
import { withStyles } from '@material-ui/core'
import Button from '@mui/material/Button'
import Checkbox from "@mui/material/Checkbox"
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
// UI Imports
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconArrowBack from '@material-ui/icons/ArrowBack'
import dateFormat from 'date-fns/format'
import dateParseISO from 'date-fns/parseISO'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
// App Imports
import params from '../../../setup/config/params'
import { messageShow } from '../../common/api/actions'
import EmptyMessage from '../../common/EmptyMessage'
import Image from '../../common/Image'
import Loading from '../../common/Loading'
import SectionPaper from '../../common/SectionPaper'
import { featureToggle, publishToggle, remove } from '../api/actions/mutation'
import { detail, listByParent } from '../api/actions/query'
import routes, { getImageSource } from '../api/routes'
import styles from './styles'



// Component
class List extends Component {

  constructor(props) {
    super(props)

    this.state = {
      category: null
    }
  }

  componentDidMount() {
    this.refresh()
  }

  refresh = () => {
    const { listByParent, match: { params: { categoryId } } } = this.props

    this.getCategory(categoryId)

    listByParent(categoryId)
  }

  getCategory = async (categoryId) => {
    const { detail } = this.props

    try {
      const { data } = await detail({ categoryId })

      if(data.success) {
        this.setState({
          category: data.data
        })
      }
    } catch(error) {
    }
  }

  onDelete = (categoryId) => async () => {
    let check = window.confirm('Are you sure you want to delete this category?')

    if(check) {
      const { remove, messageShow } = this.props

      try {
        const { data } = await remove({ categoryId })

        messageShow(data.message)

        if(data.success) {
          this.refresh()
        }
      } catch (error) {
        messageShow('Some error occurred. Please try again.')
      }
    }
  }

  onFeatureToggle = categoryId => async event => {
    const isFeatured = event.target.checked

    let check = window.confirm(`Are you sure you want to ${ isFeatured ? 'feature' : 'unfeature' } this category?`)

    if(check) {
      const { featureToggle, messageShow } = this.props

      try {
        const { data } = await featureToggle({ categoryId, isFeatured })

        messageShow(data.message)

        if(data.success) {
          this.refresh()
        }
      } catch (error) {
        messageShow('Some error occurred. Please try again.')
      }
    }
  }

  onPublishToggle = categoryId => async event => {
    const isPublished = event.target.checked

    let check = window.confirm(`Are you sure you want to ${ isPublished ? 'publish' : 'unpublish' } this category?`)

    if(check) {
      const { publishToggle, messageShow } = this.props

      try {
        const { data } = await publishToggle({ categoryId, isPublished })

        messageShow(data.message)

        if(data.success) {
          this.refresh()
        }
      } catch (error) {
        messageShow('Some error occurred. Please try again.')
      }
    }
  }

  back = () => {
    const { history } = this.props

    if(history.length > 2) {
      history.goBack()
    } else {
      history.push(routes.categoryList.path)
    }
  }

  render() {
    const { categoriesByParent: { isLoading, list }, classes } = this.props
    const { category } = this.state

    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={this.back}
          >
            <IconArrowBack />
          </IconButton>

          <Typography variant="h6" color="inherit" className={classes.grow}>
            Manage Category { category && `- ${ category.name }` }
          </Typography>
        </Toolbar>

        <SectionPaper>
          {
            isLoading
              ? <Loading />
              : list.length === 0
                ? <EmptyMessage message={'You have not added any sub categories yet.'} />
                : <Table padding={"normal"}>
                    <TableHead>
                      <TableRow>
                        <TableCell width={85}>Image</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell width={180}>Updated</TableCell>
                        <TableCell width={80} align="center">Featured</TableCell>
                        <TableCell width={80} align="center">Published</TableCell>
                        <TableCell width={180} align="center">Actions</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {
                        list.map(({ _id, image, name, isFeatured, isPublished, updatedAt }) =>
                          <TableRow key={_id}>
                            <TableCell>
                              <Link to={routes.categoryEdit.path(_id)}>
                                <Image
                                  src={getImageSource(`category/${image}`)}
                                  defaultSrc={getImageSource()}
                                />
                              </Link>
                            </TableCell>
                            <TableCell>{ name }</TableCell>
                            <TableCell>{ dateFormat(dateParseISO(updatedAt), params.date.format.full) }</TableCell>
                            <TableCell align="center">
                              <Checkbox
                                checked={isFeatured}
                                onChange={this.onFeatureToggle(_id)}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <Checkbox
                                checked={isPublished}
                                onChange={this.onPublishToggle(_id)}
                                color="primary"
                              />
                            </TableCell>
                            <TableCell align="center">
                              <Button color="secondary" component={Link} to={routes.categoryEdit.path(_id)}>Edit</Button>
                              <Button color="error" onClick={this.onDelete(_id)}>Delete</Button>
                            </TableCell>
                          </TableRow>
                        )
                      }
                    </TableBody>
                  </Table>
          }
        </SectionPaper>
      </div>
    )
  }
}

// Component Properties
List.propTypes = {
  categoriesByParent: PropTypes.object.isRequired,
  listByParent: PropTypes.func.isRequired,
  detail: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  featureToggle: PropTypes.func.isRequired,
  publishToggle: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
}

// Component State
function listState(state) {
  return {
    categoriesByParent: state.categoriesByParent
  }
}

export default connect(listState, { listByParent, detail, remove, featureToggle, publishToggle, messageShow })(withStyles(styles)(List))
