// App Imports
import {API_URL,IMAGE_SOURCE_PATH } from '../../../setup/config/env'
import params from '../../../setup/config/params'
import List from '../List'
import creategoals from '../Creategoals'
import Createrewards from'../Createrewards'
import UpdateGoals from "../UpdateGoals"

// Image
export function imagegoals(image = 'default.jpg') {
  return `${ API_URL }/${ params.goal.image.path }/${ image }`
}
export function getImageSource(image = 'default.jpg') {
  return `${ IMAGE_SOURCE_PATH }/${ image }`
}

// Pages routes
export default {
  rewardList: {
    path: '/rewards/list',
    component: List,
    exact: true,
    auth: true,
    role: params.user.roles.admin.key
  },
  creategoals: {
    path: '/rewards/creategoals',
    component: creategoals,
    exact: true,
    auth: true,
    role: params.user.roles.admin.key
  },
  createrewards: {
    path: '/rewards/createrewards',
    component: Createrewards,
    exact: true,
    auth: true,
    role: params.user.roles.admin.key
  },
  editRewards: {
    path: (goalId = ':goalId') => (`/rewards/edit/${ goalId }`),
    component: UpdateGoals,
    auth: true,
    role: params.user.roles.admin.key
  },
}
