// Imports
import { withStyles } from '@material-ui/core'
import Button from '@mui/material/Button'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import React, { PureComponent , useState }  from 'react'
import { connect } from 'react-redux'
import { messageShow } from '../../common/api/actions'
import SectionPaper from '../../common/SectionPaper'

import { remove } from '../api/actions/mutation'
import styles from './styles'
import Select from '@material-ui/core/Select';
import 'react-datepicker/dist/react-datepicker.css';
import InputBase from '@material-ui/core/InputBase'
import MenuItem from '@mui/material/MenuItem';

// Component
class Points extends PureComponent {
  
  render() {
    const {
      classes
    } = this.props;
    const defaultValue = 'select';
    return (
        <div>
      <SectionPaper>
      <TableHead>
                  <TableRow>
                  <TableCell style={{  width: 50 }}>Points</TableCell>
                  <TableCell style={{  width: 100 }}>
                  <InputBase
                  placeholder={'Points'}
                  type='number'
                  
                  />
                  </TableCell>
                  <TableCell style={{  width: 140 }}>= Dollar Value</TableCell>
                  <TableCell style={{  width: 70 }}>Currency
                  <Select value={defaultValue} style={{  width: 100 }} >
                  <MenuItem value={'select'} disabled>Select</MenuItem>
                  <MenuItem value={'option1'}>Dollar</MenuItem>
                  <MenuItem value={'option2'}>Euro</MenuItem>
                  <MenuItem value={'option3'}>Rupees</MenuItem>
                  </Select>
                  </TableCell> 
                  <TableCell style={{  width: 100 }}>Value
                  <InputBase
                  placeholder={'points'}
                  type='number'
                  inputProps={{
                  pattern: '[0-9]*', // Allows only numeric input
                  inputMode: 'numeric', // Specifies the numeric input mode
                  min: '0', // Ensures only positive numbers are allowed
                  }}
                  />
                  </TableCell>
                  <TableCell>
                    <Button>Save</Button><br/>
                    <Button>Reset</Button>
                  </TableCell>
                    
                  </TableRow>
                </TableHead>
      </SectionPaper>
      </div>
          
            
    )
  }
}

export default connect(null, {  messageShow,  })(withStyles(styles)(Points))

