// Component Styles
const styles = theme => ({
  paginationWrapper: {
    overflow: 'hidden'
  },

  pagination: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    textAlign: 'center'
  }
})

export default styles
