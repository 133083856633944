// Imports
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";

// UI Imports
import AppBar from '@mui/material/AppBar';
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@mui/material/Button";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import Stack from "@mui/material/Stack";

// App Imports
import routes from "../../../setup/routes";
import params from "../../../setup/config/params";
import { messageShow } from "../api/actions";
import { logout } from "../../user/api/actions/query";
import { grey } from "@mui/material/colors";

// Component
class Header extends PureComponent {
  onClickLogout = () => {
    let check = window.confirm("Are you sure you want to logout?");

    if (check) {
      const { logout, messageShow, history } = this.props;

      logout();

      messageShow("You have been logged out successfully.");

      history.push(routes.userLogin.path);
    }
  };

  isActiveRoute = (routePath) => {
    const { location } = this.props;

    return {
      backgroundColor:
        routePath === location.pathname ? grey[600] : "transparent",
      color: "white",
    };
  };

  homeLink = () => {
    const {
      auth: { isAuthenticated },
    } = this.props;

    return isAuthenticated ? routes.userDashboard.path : routes.userLogin.path;
  };

  render() {
    const {
      classes,
      auth: { isAuthenticated },
    } = this.props;

    return (
      <div className={classes.root}>
        <AppBar position="fixed" elevation={2}>
          <Toolbar>
            <Typography
              variant="h6"
              color="textPrimary"
              className={classes.flex}
              style={{ color: "white" }}
            >
              <font size={4} style={{cursor: 'pointer'}} onClick={this.homeLink}>{params.site.name} Admin</font>
            </Typography>

            <Stack direction="row" spacing={2}>
              <Link to={routes.pagesContact.path}>
                <Button
                  color="inherit"
                  style={this.isActiveRoute(routes.pagesContact.path)}
                >
                  Contact
                </Button>
              </Link>
              {isAuthenticated ? (
                <>
                  <Button
                    component={Link}
                    to={routes.userProfile.path}
                    style={this.isActiveRoute(routes.userProfile.path)}
                  >
                    Profile
                  </Button>

                  <Button
                    onClick={this.onClickLogout}
                    style={{ color: "white" }}
                  >
                    Logout
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    component={Link}
                    to={routes.userSignup.path}
                    style={this.isActiveRoute(routes.userSignup.path)}
                  >
                    Signup
                  </Button>
                </>
              )}
            </Stack>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

// Component Properties
Header.propTypes = {
  auth: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
};

// Component State
function headerState(state) {
  return {
    auth: state.auth,
  };
}

export default withRouter(
  connect(headerState, { logout, messageShow })(withStyles(styles)(Header))
);
