// Imports
import axios from 'axios'

// App Imports
import { API_URL } from '../../../../setup/config/env'


// Actions

export function updateDetail({ detail }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'customerProfileUpdate',
      params: detail.user
    })
  }
}

// Update
export function update({ customer }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'customerUpdate',
      params: customer
    })
  }
}