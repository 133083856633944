// Imports
import FormControl from '@material-ui/core/FormControl'
// UI Imports
import Grid from '@material-ui/core/Grid/Grid'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconArrowBack from '@material-ui/icons/ArrowBack'
import CheckIcon from '@mui/icons-material/Check';
import IconClose from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
// App Imports
import { capitalizeFirstLetter, nullToEmptyString, priceFormat } from '../../../setup/helpers'
import { messageShow } from '../../common/api/actions'
import Loading from '../../common/Loading'
import SectionPaper from '../../common/SectionPaper'
import { list } from '../../pincode/api/actions/query'
import { updateDetail } from '../api/actions/mutation'
import { detail } from '../api/actions/query'
import routes from '../api/routes'
import styles from './styles'




class Update extends Component {
    constructor(props) {
        super(props)

        this.state = {

            detail: {
                user: {
                    name: '',
                    email: '',
                    gender: '',
                    dateOfBirth: '',
                    isVerified: '',
                    pincodeId: '',
                    simpl: {
                        isApproved: ''
                    }
                },
                wallet: [],
                walletBalance: 0,
                kyc: []
            },
            verifiedList: ['Verified', 'Not Verified'],
            genderList: ['Male', 'Female', 'Other'],
            pincodeList: [],
            isLoading: true,
            isLoadingSubmit: false,
            selectedPincode: ''
        }
    }

    componentDidMount() {
        this.getDetails()
        this.getPincodeList()
    }

    getPincodeList = async () => {
        const { list } = this.props
        await list();
        const { pincodes } = this.props
        this.setState({ pincodeList: pincodes.list })
    }

    getDetails = async () => {
        const { detail, match: { params: { userId } } } = this.props

        this.isLoadingToggle(true)

        try {
            const { data } = await detail({ userId })
            if (data.success) {
                this.setState({
                    detail: data.data,
                })
            } else {
                messageShow(data.message)
            }
        } catch (error) {
            messageShow('Some error occurred. Please try again.')
        } finally {
            this.isLoadingToggle(false)
        }

        this.setState({ selectedPincode: this.state.detail.user.pincodeId && this.state.detail.user.pincodeId._id })
        this.setState({ selectedAccount: this.state.detail.user.simpl })
    }

    isLoadingToggle = (isLoading) => {
        this.setState({
            isLoading
        })
    }

    onType = ({ target: { name, value } }) => {
        const { detail } = this.state

        detail.user[name] = capitalizeFirstLetter(value)

        this.setState({ detail })
    }

    onUpdate = async event => {
        event.preventDefault()

        const { updateDetail, messageShow, history } = this.props

        const { detail } = this.state

        this.isLoadingSubmitToggle(true)

        try {
            const { data } = await updateDetail({ detail })

            this.isLoadingSubmitToggle(false)

            messageShow("Customer Details update Successfully")

            if (data.success) {
                if (history.length > 2) {
                    history.goBack()
                } else {
                    history.push(routes.customerList.path(1))
                }
            }
        } catch {

        }

    }

    isLoadingSubmitToggle = (isLoadingSubmit) => {
        this.setState({ isLoadingSubmit })
    }

    handleChange = event => {
        event.preventDefault();
        const detail = { ...this.state.detail }

        if (event.target.name === 'gender') {
            detail.user.gender = event.target.value
        } if (event.target.name === 'verified') {
            if (event.target.value === 'Verified') {
                detail.user.isVerified = true
            } if (event.target.value === 'Not Verified') {
                detail.user.isVerified = false
            }
        }

        this.setState({ detail })
    }

    pincodeChange = event => {
        event.preventDefault();
        const detail = { ...this.state.detail }

        const { pincodeList } = this.state

        const filterArray = pincodeList.filter(obj => obj._id === event.target.value)

        const finalValue = filterArray[0]

        detail.user.pincodeId = finalValue

        this.setState({ selectedPincode: event.target.value })

    }

    render() {
        const { match: { params: { userId } } } = this.props
        const { isLoading } = this.props
        const { detail } = this.state
        const { genderList, pincodeList, isLoadingSubmit, verifiedList, selectedPincode } = this.state

        return (
            <div>
                <Toolbar className={styles.toolbar}>
                    <Link to={routes.customerDetail.path(userId)}>
                        <IconButton color="inherit">
                            <IconArrowBack />
                        </IconButton>
                    </Link>

                    <Typography variant="h6" color="inherit" >
                        Edit Customer
                    </Typography>
                </Toolbar>
                <Grid item xs={12} lg={6}>
                    <SectionPaper padding>
                        {
                            isLoading
                                ? <Loading />
                                : <form onSubmit={this.onUpdate}>
                                    {/* Input - Name */}
                                    <Grid item xs={12}>
                                        <TextField
                                            name={'name'}
                                            value={nullToEmptyString(detail.user.name)}
                                            onChange={this.onType}
                                            label={'Name'}
                                            placeholder={'Enter customer name'}
                                            margin={'dense'}
                                            autoComplete={'off'}
                                            fullWidth
                                            autoFocus
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name={'email'}
                                            value={nullToEmptyString(detail.user.email.toLowerCase())}
                                            onChange={this.onType}
                                            label={'Email'}
                                            placeholder={'Enter Email'}
                                            margin={'dense'}
                                            autoComplete={'off'}
                                            fullWidth
                                            autoFocus
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth margin={'dense'}>
                                            <InputLabel htmlFor="pincode">Pincode</InputLabel>
                                            <Select
                                                value={selectedPincode || ''}
                                                onChange={this.pincodeChange}
                                                name={selectedPincode}
                                            >
                                                {pincodeList.map((obj, key) =>
                                                    <MenuItem value={obj._id} key={key} >
                                                        {obj.name}
                                                    </MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth margin={'dense'} >
                                            <InputLabel htmlFor="gender">Gender</InputLabel>
                                            <Select
                                                value={detail.user.gender || ''}
                                                onChange={this.handleChange}
                                                name={'gender'}
                                            >
                                                {genderList.map((obj, key) =>
                                                    <MenuItem value={obj} key={key}>
                                                        {obj}
                                                    </MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="date"
                                            label={'Date Of Birth'}
                                            type="date"
                                            name={'dateOfBirth'}
                                            onChange={this.onType}
                                            value={nullToEmptyString(detail.user.dateOfBirth)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                            margin={'dense'}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name={'walletBalance'}
                                            value={nullToEmptyString(priceFormat(detail.walletBalance))}
                                            onChange={this.onType}
                                            label={'Wallet Balance'}
                                            placeholder={'Wallet Balance'}
                                            margin={'dense'}
                                            autoComplete={'off'}
                                            fullWidth
                                            autoFocus
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth margin={'dense'} >
                                            <InputLabel htmlFor="verified">Verified</InputLabel>
                                            <Select
                                                value={nullToEmptyString(detail.user.isVerified ? 'Verified' : 'Not Verified')}
                                                onChange={this.handleChange}
                                                name="verified"
                                            >
                                                {verifiedList.map((obj, key) =>
                                                    <MenuItem value={obj} key={key}>
                                                        {obj}
                                                    </MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} style={{ textAlign: 'right', marginTop: '20px' }}>
                                        <Link to={routes.customerDetail.path(userId)}>
                                            <IconButton
                                                type={'button'}
                                                aria-label={'Close'}
                                            >
                                                <IconClose />
                                            </IconButton>
                                        </Link>

                                        <IconButton
                                            type={'submit'}
                                            aria-label={'Save'}
                                            color={'primary'}
                                            disabled={isLoadingSubmit}
                                        >
                                            <CheckIcon/>
                                        </IconButton>
                                    </Grid>
                                </form>
                        }
                    </SectionPaper>
                </Grid>
            </div>
        )
    }
}

Update.propTypes = {
    pincodes: PropTypes.object.isRequired,
    list: PropTypes.func.isRequired,
    detail: PropTypes.func.isRequired,
    updateDetail: PropTypes.func.isRequired,
    messageShow: PropTypes.func.isRequired,
}

// Component State
function listState(state) {
    return {
        pincodes: state.pincodes
    }
}

export default connect(listState, { list, detail, updateDetail, messageShow })(withStyles(styles)(Update))
