// Imports
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// // UI Imports
import Grid from '@material-ui/core/Grid/Grid'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@mui/material/Button'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CheckIcon from '@mui/icons-material/Check';
import IconArrowBack from '@material-ui/icons/ArrowBack'
import IconClose from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'

// // App Imports
import { nullToEmptyString, capitalizeFirstLetter } from '../../../setup/helpers'
import { upload, messageShow } from '../../common/api/actions'
import SectionPaper from '../../common/SectionPaper'
import Loading from '../../common/Loading'
import Image from '../../common/Image'
import { detail } from '../api/actions/query'
import { update } from '../api/actions/mutation'
import { imageUser } from '../../user/api/routes'


// Component
class Update extends Component {

  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      isLoadingSubmit: false,
      vendor: {
        name: '',
        email: '',
        mobile: '',
        image: ''
      }
    }
  }

  componentDidMount() {
    this.getDetails()
  }

  getDetails = async () => {
    const { match: { params: { vendorId } }, detail } = this.props

    try {
      const { data } = await detail({ userId: vendorId })

      if (data.success) {
        this.setState({ vendor: data.data.user })
      } else {
        messageShow(data.message)
      }
    } catch (error) {
      messageShow('Some error occurred. Please try again.')
    } finally {
      this.isLoadingToggle(false)
    }
  }

  isLoadingToggle = (isLoading) => {
    this.setState({
      isLoading
    })
  }

  onType = ({ target: { name, value } }) => {
    const { vendor } = this.state

    if (name === 'email') {
      vendor[name] = value
    } else {
      vendor[name] = capitalizeFirstLetter(value)
    }

    this.setState({ vendor })
  }

  onUpload = async event => {
    const { upload, messageShow } = this.props

    messageShow('Uploading file, please wait...')

    let file = new FormData()
    file.append('type', 'product')
    file.append('file', event.target.files[0])

    // Upload image
    try {
      const { data } = await upload(file)

      if (data.success) {
        messageShow('File uploaded successfully.')

        const { vendor } = this.state

        setTimeout(() => {
          vendor.image = data.file
          this.setState({
            vendor
          })
        }, 1500)

      } else {
        messageShow('There was some error. Please try again.')
      }
    } catch (error) {
      messageShow('There was some error. Please try again.')
    } finally {
    }
  }

  submit = async event => {
    event.preventDefault()

    const { vendor } = this.state
    const { update, history } = this.props

    this.isLoadingSubmitToggle(true)

    try {
      const { data } = await update({ vendor })

      this.isLoadingSubmitToggle(false)

      if (data.success) {
        messageShow("Customer Details update Successfully")

        history.goBack()
      } else {
        messageShow("Something went wrong !!!")
      }

    } catch {
      messageShow('Something went wrong !!!')
    }
  }

  isLoadingSubmitToggle = (isLoadingSubmit) => {
    this.setState({ isLoadingSubmit })
  }
  
  back = () => {
    const { history } = this.props

    history.goBack()
    
  }

  render() {
    const { isLoading, vendor, isLoadingSubmit } = this.state

    return (
        <div>
          <Toolbar className={styles.toolbar}>
            <IconButton color="inherit" onClick={this.back}>
              <IconArrowBack />
            </IconButton>

            <Typography variant="h6" color="inherit" >
              Edit Profile
          </Typography>
          </Toolbar>
          <Grid item xs={12} lg={6}>
            <SectionPaper padding>
              {
                isLoading
                  ? <Loading />
                  : <form onSubmit={this.submit}>
                    {/* Input - Name */}
                    <Grid item xs={12}>
                      <TextField
                        name={'name'}
                        value={nullToEmptyString(vendor.name)}
                        onChange={this.onType}
                        label={'Name'}
                        placeholder={'Enter vendor name'}
                        margin={'dense'}
                        autoComplete={'off'}
                        fullWidth
                        autoFocus
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        name={'email'}
                        value={nullToEmptyString(vendor.email.toLowerCase())}
                        onChange={this.onType}
                        label={'Email'}
                        placeholder={'Enter Email'}
                        margin={'dense'}
                        autoComplete={'off'}
                        fullWidth
                        autoFocus
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        name={'mobile'}
                        type={'number'}
                        value={nullToEmptyString(vendor.mobile)}
                        onChange={this.onType}
                        label={'Mobile'}
                        placeholder={'Enter Mobile'}
                        margin={'dense'}
                        autoComplete={'off'}
                        fullWidth
                        autoFocus
                      />
                    </Grid>

                    <Grid item sm={6} style={{ paddingTop: '30px' }}>
                      <div style={{ marginBottom: 20 }}>
                        <input
                          accept={'image/png,image/jpeg'}
                          style={{ display: 'none' }}
                          id={'contained-button-file'}
                          type={'file'}
                          onChange={this.onUpload}
                        />

                        <label htmlFor={'contained-button-file'}>
                          <Button
                            variant={'outlined'}
                            component={'span'}
                            type={'file'}
                            fullWidth
                          >
                            Upload Image
                        </Button>
                        </label>
                      </div>

                      <a href={'#'} target={'_blank'}>
                        <Image
                          src={vendor.image}
                          defaultSrc={imageUser()}
                          size={150}
                        />
                      </a>
                    </Grid>

                    <Grid item xs={12} style={{ textAlign: 'right', marginTop: '20px' }}>
                      <IconButton
                        type={'button'}
                        aria-label={'Close'}
                        onClick={this.back}
                      >
                        <IconClose />
                      </IconButton>

                      <IconButton
                        type={'submit'}
                        aria-label={'Save'}
                        color={'primary'}
                        disabled={isLoadingSubmit}
                      >
                        <CheckIcon/>
                      </IconButton>
                    </Grid>
                  </form>
              }
            </SectionPaper>
          </Grid>
        </div>
    )
  }
}

Update.propTypes = {
  detail: PropTypes.func.isRequired,
  upload: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired
}


export default connect(null, { detail, upload, messageShow, update })(withStyles(styles)(Update))
