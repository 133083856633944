// Imports
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

// UI Import
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import CheckIcon from '@mui/icons-material/Check';
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'

// App Imports
import { nullToEmptyString } from '../../../setup/helpers'
import { getUserDetails } from '../api/actions/query'
import { updatePassword } from '../api/actions/mutation'
import routes from '../../../setup/routes'
import { messageShow } from '../../common/api/actions';

class ResetPassword extends PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      password: '',
      confirmPassword: '',
      isLoading: false,
      message: '', 
      isSubmit: false,
      // switchPage: ''
    }
  }

  componentDidMount() {
    this.getDetails()
  }

  getToken = () => {
    const pathname = this.props.history.location.pathname
    const url = pathname.split('/');
    const token = url[2]
    return token
  }

  getDetails =  async () => {
    const { getUserDetails } = this.props

    const token = this.getToken()

    const isValid = await getUserDetails({ token })

    if (isValid.data.success) {
      this.setState({
        isLoading: true,
        message: 'Password update successfully.'
      })
    } else {
      this.setState({
        isLoading: false,
        message: 'Link expired. Please send another email.'
      })
    }
  }

  onType = event => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  checkPasswordMatch = () => {
    const { password, confirmPassword } = this.state

    if (password === confirmPassword) {
      return true
    } else {
      return false
    }
  }

  submit = async event => {
    event.preventDefault()
    const { password, isSubmit, switchPage } = this.state
    const { updatePassword, messageShow } = this.props

    const token = this.getToken()

    if (this.checkPasswordMatch()) {
      this.setState({ isSubmit: true })
      await updatePassword({ token, password })
      messageShow('Profile has been updated successfully.')
    } else {
      this.setState({ isSubmit: false })
      messageShow('Password does not match!')
    }
  }


  render() {
    const { classes } = this.props
    const { password, confirmPassword, isLoading, message, isSubmit, switchPage } = this.state
    
    return (
      <div className={classes.root}>       
        { isSubmit ? <Redirect to={routes.userLogin.path} component={Link} />: '' }
        {
          isLoading ? 
            <Paper className={classes.container}>
              <Typography
                variant={'subtitle1'}
                color={'inherit'}
                className={classes.heading}
              >
                Please change password
              </Typography>
              <form onSubmit={this.submit}>
                {/* Input - password */}
                <Grid item xs={12}>
                  <TextField
                    name={'password'}
                    type={'password'}
                    value={nullToEmptyString(password)}
                    onChange={this.onType}
                    label={'Password'}
                    placeholder={'Enter password'}
                    required={true}
                    margin={'dense'}
                    autoComplete={'off'}
                    fullWidth
                  />
                </Grid>

                {/* Input - Confirm password */}
                <Grid item xs={12}>
                  <TextField
                    name={'confirmPassword'}
                    type={'password'}
                    value={nullToEmptyString(confirmPassword)}
                    onChange={this.onType}
                    label={'Confirm Password'}
                    placeholder={'Enter password'}
                    required={true}
                    margin={'dense'}
                    autoComplete={'off'}
                    fullWidth
                  />
                </Grid>

                {/* Button -  Save */}
                <Grid item xs={12} className={classes.buttonsContainer}>
                  <IconButton
                    type={'submit'}
                    aria-label={'Save'}
                    color={'primary'}
                  >
                    <CheckIcon/>
                  </IconButton>
                </Grid>
              </form>
            </Paper>  
          :
          <Paper className={classes.container}>
            <Typography
              variant={'subtitle1'}
              color={'inherit'}
              className={classes.heading}
            >
              {message}
            </Typography>
          </Paper>
        }
      </div>
    )
  }
}


// Component Properties
ResetPassword.propTypes = {
  auth: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  getUserDetails: PropTypes.func.isRequired,
  updatePassword: PropTypes.func.isRequired,
}

// Component State
function loginState(state) {
  return {
    auth: state.auth
  }
}

export default connect(loginState, { getUserDetails, updatePassword, messageShow })(withStyles(styles)(ResetPassword))

