// App Imports
import Contact from '../Contact'

// Pages routes
export default {
  pagesContact: {
    path: '/contact',
    component: Contact,
    exact: true
  }
}
