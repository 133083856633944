// Component Styles
const styles = {
  sidenavWrapper: {
    backgroundColor: '#ffffff',
    width: 240,
    display: 'flex',
    borderRight: `1px solid rgba(0, 0, 0, 0.12)`,
    marginTop: 1
  },
  sidenav: {
    width: '100%'
  },

  inactive: {
    color: '#999'
  },
  menuActive: {
    backgroundColor: `rgba(0, 0, 0, 0.04)`
  }
}

export default styles
