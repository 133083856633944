// App Imports
import { LIST_PARENT_DONE, LIST_PARENT_REQUEST, LIST_PARENT_RESET, LIST_PARENT_RESPONSE } from '../actions/types'

// List

// Initial State
const deliveriesBoyParentInitialState = {
  isLoading: false,
  list: []
}

// State
export default (state = deliveriesBoyParentInitialState, action) => {
  switch (action.type) {
    case LIST_PARENT_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading
      }

    case LIST_PARENT_RESPONSE:
      return {
        ...state,
        list: action.list
      }

    case LIST_PARENT_DONE:
      return {
        ...state,
        isLoading: false
      }

    case LIST_PARENT_RESET:
      return { ...deliveriesBoyParentInitialState }

    default:
      return state
  }
}
