// Imports
import React, { Component } from "react";
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import ImageComp from '../../common/Image';
import FileUpload from '@mui/icons-material/FileUpload';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { messageShow,upload } from '../../common/api/actions';
import SectionPaper from '../../common/SectionPaper';
import IconArrowBack from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import { remove } from '../api/actions/mutation';
import routes from '../api/routes';
import axios from 'axios';
import styles from './styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import dayjs from "dayjs";
import TextField from '@mui/material/TextField';
import Grid from '@material-ui/core/Grid/Grid';
import {
  nullToEmptyString,
} from "../../../setup/helpers";
import PropTypes from "prop-types";
import { createGoals} from "../api/actions/mutation";
import { getImageSource } from "../api/routes";
// Component
class Creategoals extends Component {
  constructor(props) {
    super(props);
  
    this.goals = {
      goalName: "",
      description:"",
      eventName:"",
      startDate: new Date(),
      expiryDate: new Date(),
      rewardPoints: "",
      multiplierEvent: "",
      image: "default.jpg",
    };
  
    this.state = {
      isLoading: false,
      isLoadingSubmit: false,
      isUploadingFile: false,
      goals: this.goals,
      previewImage: null,
    };
  }
  
  getGoals = () => {
    const { listGoals } = this.props;

    listGoals();
  };

  onCreate = async (event) => {
    event.preventDefault();

    const {  createGoals, messageShow,history} = this.props;

    const { goals } = this.state;

    this.isLoadingSubmitToggle(true);

    try {
      const { data } = await createGoals(goals);

      this.isLoadingSubmitToggle(false);

      messageShow(data.message);

      if (data.success) {
        history.push(routes.rewardList.path);
      }
    } catch (error) {
      this.isLoadingSubmitToggle(false);

      messageShow("Some error occurred. Please try again.");
    }
  };

  isLoadingToggle = (isLoading) => {
    this.setState({
      isLoading,
    });
  };

  isLoadingSubmitToggle = (isLoadingSubmit) => {
    this.setState({
      isLoadingSubmit,
    });
  };

  isUploadingFileToggle = (isUploadingFile) => {
    this.setState({
      isUploadingFile,
    });
  };

  onDateChange = (date) => {
    this.setState({
      selectedDate: date,
    });
  };
  
  onType = (event) => {
    const { goals } = this.state;
    const { name, value } = event.target;
    goals[name] = value;
    this.setState({ goals });
  };
  onUpload = async (event) => {
    const { upload, messageShow } = this.props;
    messageShow("Uploading file, please wait...");
    this.isUploadingFileToggle(true);
    const imgFile = event.target.files[0];
    let file = new FormData();
    file.append("type", "goals");
    file.append("name", this.state.goals.goalName);
    file.append("file", imgFile);
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const fileResult = fileReader.result;
      let dataAsImg = new Image();
      dataAsImg.src = fileResult;
      this.setState({ previewImage: dataAsImg.src });
    };
    fileReader.readAsDataURL(imgFile);
    // Upload image
    try {
      const { data } = await upload(file);

      if (data.success) {
        
       this.props.messageShow("File uploaded successfully.");
        const { goals } = this.state;
        setTimeout(() => {
          goals.image = data.file;
          this.setState({
            goals,
          });
        }, 1000);
      }else {
        messageShow("There was some error. Please try again.");
      }
    } catch (error) {
      messageShow("There was some error. Please try again.");
    } finally {
      this.isUploadingFileToggle(false);
    }
  };
  
  back = () => {
    const { history } = this.props;

    if (history.length > 2) {
      history.goBack();
    } else {
      history.push(routes.goals.path(1));
    }
  };
  handleDateChange = (date) => (value) => {
    this.setState({
      [date]: new Date(value),
    });
  };

  render() {
    const {classes} = this.props;
    const { goals,isLoadingSubmit } =
      this.state;
    return (
      <SectionPaper>
        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={this.back}
          >
            <IconArrowBack />
          </IconButton>

          <Typography variant="h6" color="inherit" >
             Create Goals
          </Typography>
        </Toolbar>
          
          <Toolbar className={classes.toolbar}>
           <Grid item xs={12} lg={6}>
           <SectionPaper padding>
      <form onSubmit={this.onCreate}>
        <Grid item xs={12} style={{ marginBottom: 24 }}>
          <TextField
            name={"goalName"}
            value={nullToEmptyString(goals.goalName)}
            variant="standard"
            onChange={this.onType}
            label={"Goal Name"}
            placeholder={"Enter goal name"}
            required={true}
            margin={"dense"}
            autoComplete={"off"}
            fullWidth
            autoFocus
          />
        </Grid>
          
          <Grid item xs={12} style={{ marginBottom: 24 }}>
                  <TextField
                    name={"description"}
                    value={nullToEmptyString(this.goals.description)}
                    variant="standard"
                    onChange={this.onType}
                    label={"Description"}
                    placeholder={"Enter description"}
                    margin={"dense"}
                    autoComplete={"off"}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 24 }}>
                <TextField
                  name="eventName"
                  value={nullToEmptyString(this.goals.eventName)}
                  variant="standard"
                  onChange={this.onType}
                  label="Event name"
                  placeholder={"Enter event name"}
                  required={true}
                  margin={"dense"}
                  autoComplete={"off"}
                  fullWidth
                />
                </Grid>

                <Grid container spacing={3}>


                <Grid item xs={6} style={{ marginBottom: 24 }}>
                <TextField
                  name={"multiplierEvent"}
                  value={nullToEmptyString(this.goals.multiplierEvent)}
                  variant="standard"
                  onChange={this.onType}
                  label={"Multiplier event"}
                  placeholder={"Enter number of multiplier events"}
                  required={true}
                  margin={"dense"}
                  autoComplete={"off"}
                  fullWidth
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                />
                </Grid>
                <Grid item xs={6} style={{ marginBottom: 24 }}>
                  <TextField
                    name={"rewardPoints"}
                    value={nullToEmptyString(this.goals.rewardPoints)}
                    variant="standard"
                    onChange={this.onType}
                    label={"Rewards points"}
                    placeholder={"Enter number of rewards points"}
                    required={true}
                    margin={"dense"}
                    autoComplete={"off"}
                    fullWidth
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  />
                </Grid>
                </Grid>

                <Grid item xs={12} container spacing={2} style={{ marginBottom: 24 }}>
                <Grid item xs={6} >
                  <DatePicker
                    required={true}
                    label="Start Date"
                    value={dayjs(this.state.goals.startDate)}
                    slotProps={{ textField: { variant: "standard" } }}
                    onChange={this.handleDateChange("startDate")}
                  />
                </Grid>
                <Grid item xs={6} >
                  <DatePicker
                    required={true}
                    value={dayjs(this.state.goals.expiryDate)}
                    label="Expiry Date"
                    slotProps={{ textField: { variant: "standard" } }}
                    onChange={this.handleDateChange("expiryDate")}
                    
                  />
                </Grid>
                <Grid
                  container
                  spacing={8}
                  className={classes.buttonUpload}
                  alignItems="center"
                >
                  <Grid item md={6} >
                      {!this.state.previewImage ? (
                        <a
                          href={getImageSource(goals.image)}
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          <ImageComp
                            src={getImageSource(`goals/${goals.image}`)}
                            defaultSrc={getImageSource()}
                            size={"100%"}
                          />
                        </a>
                      ) : (
                        <img
                          height="auto"
                          width="100%"
                          src={this.state.previewImage}
                          alt="preview_goals"
                        ></img>
                      )}
                      </Grid>
                  <Grid item md={6}>
                    <input
                      accept={"image/png,image/jpeg"}
                      style={{ display: "none" }}
                      id={"contained-button-file"}
                      type={"file"}
                      onChange={this.onUpload}
                    />

                    <label htmlFor={"contained-button-file"}>
                      <Button
                        variant={"outlined"}
                        component={"span"}
                        type={"file"}
                        fullWidth
                      >
                        <FileUpload
                          fontSize="16"
                          style={{ marginInlineEnd: 16 }}
                        />
                        Upload Image
                      </Button>
                    </label>
                  </Grid>
                </Grid>
                
              </Grid>
              
        <Grid item xs={12} className={classes.buttonsContainer}>
            <Button
              type={"button"}
              aria-label={"Close"}
              variant="outlined"
              color="secondary"
              onClick={this.back}
            >
              Cancel
            </Button>
          <Button
            style={{ marginInlineStart: 12 }}
            type={"submit"}
            aria-label={"Save"}
            color={"primary"}
            variant="contained"
            disabled={isLoadingSubmit}
            
          >
            Save
          </Button>
        </Grid>
      </form>
    
  </SectionPaper>
</Grid>
      </Toolbar>
      
      </SectionPaper>
    )
  }
}
Creategoals.propTypes = {
  
  createGoals: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};
function createOrEditState(state) {
  return {
    categories: state.categories,
  };
}

export default connect(createOrEditState, {
  createGoals,
  messageShow,
  upload,
  messageShow,
})(withStyles(styles)(Creategoals));


