// Actions Types

// Pincode List
export const LIST_REQUEST = 'PINCODE/LIST/REQUEST'
export const LIST_RESPONSE = 'PINCODE/LIST/RESPONSE'
export const LIST_DONE = 'PINCODE/LIST/DONE'
export const LIST_RESET = 'PINCODE/LIST/RESET'

// Area List
export const AREA_LIST_REQUEST = 'AREA/LIST/REQUEST'
export const AREA_LIST_RESPONSE = 'AREA/LIST/RESPONSE'
export const AREA_LIST_DONE = 'AREA/LIST/DONE'
export const AREA_LIST_RESET = 'AREA/LIST/RESET'
